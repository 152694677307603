import * as React from 'react';

import { DateRangePicker } from "react-date-range";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Methods from 'Utilities/CommonMethods';

export interface ISelectionRange{
    startDate: Date | undefined;
    endDate: Date | undefined;
    key: string;
}

interface IDateTimeRangePicker {
    selectionRange: ISelectionRange;
    setSelectionRange: (arg: ISelectionRange | undefined) => void;
    marginTop: number;
}

const DateTimeRangePicker: React.FC<IDateTimeRangePicker> = ({ selectionRange, setSelectionRange, marginTop }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = React.useCallback((ranges: { selection?: ISelectionRange; range1?: ISelectionRange }) => {
        if (ranges?.selection)
            setSelectionRange(ranges.selection);
        if (ranges?.range1) 
            setSelectionRange({ ...ranges?.range1, key: "selection" });
    }, []);

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
                size="small"
                style={{ marginTop: marginTop, height: 38, minWidth: '160px' }}
                endIcon={!selectionRange?.startDate ? null : <CloseIcon fontSize="small" onClick={() => setSelectionRange({
                    endDate: undefined, key: '', startDate: undefined
                })} />}                
            >
                { selectionRange?.startDate ? `${Methods.getDateByFormat(selectionRange?.startDate as any, "MMMM Do YYYY")} - ${Methods.getDateByFormat(selectionRange?.endDate as any, "MMMM Do YYYY")}` : 'Select Date Range' }
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={(ranges: any) => handleSelect(ranges)}
                    startDatePlaceholder="Start Date" endDatePlaceholder='End Date'
                />
            </Menu>
        </>
        
    )
}

export default DateTimeRangePicker;