import React from "react";
import './PrivateLayout.scss';

import Header from "Components/Header/Header";
import Sidebar from "Components/Sidebar/Sidebar";

interface IPrivateLayout{
    children: JSX.Element;
}

const PrivateLayout:React.FC<IPrivateLayout> = ({ children }) => {
    return (
        <div className="container-fluid h-100 p-0">
            <div className="wrapper h-100 d-flex">
                <div className="sidebar-container h-100 d-flex">
                    <Sidebar />
                </div>
                <div className="content-container flex-grow-1 h-100">
                    <Header />
                    <div className="content">
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PrivateLayout;