import React, { useEffect, useState } from 'react';
import './UserActivity.scss';

import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Paper, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { onGetUserActivity, userActivitySelector } from 'Redux/Slices/UsersSlice';
import { AppDispatch } from 'Redux/Store/index';
import Methods from 'Utilities/CommonMethods';

const UserActivity: React.FC = () => {
    const userInfo = useSelector(userActivitySelector);
    const [pageCount, setPageCount] = useState(0);
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        const _count = userInfo?.count;
        if(_count > 0) {
            setPageCount(Math.ceil(_count / 10))
        } else {
            setPageCount(0)
        }
    }, [userInfo]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const email = userInfo.results[0]?.actor;
        dispatch(onGetUserActivity({ page: value, user_email: email }));
    };

    return (
        <>
            <div className='popup-container p-3'>
                <h5>Activity log</h5>
                <hr />
                {userInfo.results.length > 0 ?
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Entity</TableCell>
                                <TableCell>User Action</TableCell>
                                <TableCell>Creation Time</TableCell>
                                <TableCell>IP Address</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                userInfo.results.map(activity => (
                                    <Row key={activity.id} row={activity} />
                                ))
                            }
                            
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <p>No logs to show</p>
                }
                <hr />
                <Pagination count={pageCount} color="primary" onChange={handlePageChange} />
            </div>
        </>
    )
};

const Row: React.FC<{row:any}> = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const getDateTimeFormat = (date:string) => {
        const _date = new Date(date);
        const hh = _date.getUTCHours();
        const mm = _date.getUTCMinutes();
        const hours = hh > 12 ? hh - 12 : hh;
        const minutes = mm;
        return `${_date.toDateString()} at ${hours > 9 ? hours : "0" + hours}:${minutes > 9 ? minutes : "0" + minutes } ${hh > 11 ? "pm" : "am"}`;
    }    

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.entity}
                </TableCell>
                <TableCell>{row.action}</TableCell>
                <TableCell>{Methods.getEasternTimeZone(row.creation_time)}</TableCell>
                <TableCell>{row?.ip_address ?? ''}</TableCell>
                <TableCell align="right" className={row.response_status_code > 290 ? 'error' : 'success'}>{row.response_status_code}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className="w-100 activity-collapse p-3">
                            <div className="d-flex">
                                <div className="col">
                                    <TextField label="Request" value={JSON.stringify(row.request)} multiline rows={4} placeholder="Feel free to leave a message." name='message'  fullWidth margin="normal" InputLabelProps={{ shrink: true }} variant="outlined" />
                                </div>
                                <div className="col">
                                    <TextField label="Response" value={JSON.stringify(row.response_data)} multiline rows={4} placeholder="Feel free to leave a message." name='message' fullWidth margin="normal" InputLabelProps={{ shrink: true }} variant="outlined" />
                                </div>
                            </div>
                            <div className="d-flex w-50">
                                <div className="col">
                                    <TextField label="Location Details" value={JSON.stringify(row.location_details)} multiline rows={4} placeholder="Feel free to leave a message." name='message' fullWidth margin="normal" InputLabelProps={{ shrink: true }} variant="outlined" />
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default UserActivity
