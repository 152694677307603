import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { StoreDef } from 'Redux/Store/index';

const metadataSlice = createSlice({
    name: "sidebar",
    initialState: {
        title: '',
        enableSpinner: false,
    },
    reducers: {
        setSpinner: (state, action) => {
            state.enableSpinner = action.payload;
        }
    },
});

const selfSelect = (state: StoreDef) => state.metadata;

export const spinnerStatusSelector = createDraftSafeSelector(selfSelect, state => state.enableSpinner);

export const { setSpinner } = metadataSlice.actions;

export default metadataSlice.reducer;