import {Alert, Snackbar} from "@mui/material";
import * as React from "react";

interface IAlertPopup {
    open: boolean;
    setClose: (flag: boolean)=> void;
}

export const AlertPopup: React.FC<IAlertPopup>  = ({open, setClose}) => {
    return(
        <Snackbar open={true} autoHideDuration={3000} onClose={()=>setClose(false)}>
            <Alert onClose={()=>setClose(false)} severity="error" sx={{ width: '100%' }}>
                Something Went Wrong. Please try Again!
            </Alert>
        </Snackbar>
    )
}
