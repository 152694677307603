import React, { useEffect } from 'react';

import { Table } from 'antd';
import HistoryDetail from './DetailHistory';
import { handleUpdatedFilter, SortingData} from "Utilities/Pagination";
import Methods from "Utilities/CommonMethods";
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import ApiUrls from "Definations/ApiUrls";
import Pagination from "@mui/material/Pagination";
import {AlertPopup} from "Components/Alert/Alert";
import ScreenFilter from 'Components/ScreenFilter/ScreenFilter';
import { IScreenFilter } from 'Utilities/Interfaces';
import { useDebounce } from 'Hooks/UseDebounce';

const TemplateHistory = () => {

    const [page, setPage] = React.useState<number>(1);
    const [selectedFilters, setSelectedFilters] = React.useState<IScreenFilter[]>([]);
    const [updatedhistory, setUpdatedHistory] = React.useState<any>([]);
    const [updateCount, setUpdateCount] = React.useState<number>(10);
    const [isError, setIsError] = React.useState<{ data: any, flag: boolean }>({ data: '', flag: false });
    const [sort, setSort] = React.useState<any>();

    const debouncedValue: any = useDebounce(selectedFilters.length > 0 ? selectedFilters[0].value : '', 1000);

    useEffect(() => {
        if (debouncedValue) {
            handleFilters(debouncedValue);
        } else {
            handleChange(1);   
        }
    }, [debouncedValue]);

    const columns = [
        {
            title: 'Company',
            dataIndex: 'company_name',
        },
        {
            title: 'Users',
            dataIndex: 'user_id',
            sorter: true,
            render: (text: string, record: Object) => (
                <p > <b>{text}</b></p>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (text: string, record: object) => (
                <p>{text}</p>
            ),
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            sorter: true,
            render: (text: string, record: any) => (
                <p> { Methods.getEasternTimeZone(text) } </p>
            ),
        },
        {
            title: 'Action',
            render: (text: any, record: any) => (
                <div className="dropdown dropdown-action text-end">
                    <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a onClick={() => console.log(record.id)} data-bs-toggle="modal" data-bs-target="#delete_user"><i className="fa fa-trash-o m-r-5" /> Delete</a>
                        <br />
                        <a data-bs-toggle="modal" data-bs-target="#see_history" onClick={() => setUpdatedHistory(record)}><i className="fa fa-eye" /> See more</a>
                        <br />
                        <a data-bs-toggle="modal" data-bs-target="#see_user"><i className="fa fa-pencil" />Edit</a>

                        {/* <a className="dropdown-item" onClick={() => setId(record.id)} data-bs-toggle="modal" data-bs-target="#delete_load"><i className="fa fa-trash-o m-r-5" /> Delete</a> */}
                    </div>
                </div>
            ),
        },
    ];

    const handleTableChange = (newPagination: any, filters: any, sorter: any): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        const sortOrder = sorter === 'user_id' ? 'user_id__email' : sorter;
        setSort(sortOrder);
        const obj = { page, filter, filterValue };

        SortingData(sortOrder, ApiUrls.templateHistory, setUpdatedHistory, obj, (err: any) => (setIsError(err)) );
    };

    const handleFilters = React.useCallback((value: string): void => {
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        setPage(1);

        if(filter){
            handleUpdatedFilter(value, setUpdatedHistory, ApiUrls.templateHistory, page, value ? filter : '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort   );
        }
    }, [page, selectedFilters, sort]);

    const handleChange = React.useCallback((pg: number): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';

        handleUpdatedFilter(filterValue, setUpdatedHistory, ApiUrls.templateHistory, pg, filterValue ? filter : '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort  );
    }, [selectedFilters, sort]);

    return (
        <DocumentTitle title="Template History" condition={true}>
            <div className="page-wrapper">
                <ScreenFilter 
                    dropdownArray={Methods.getScreenFilters([2, 5])} onSearch={(txt) => handleFilters(txt)} totalRows={updateCount}
                    selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters}
                />
                <div className="table-responsive shadow bg-white p-2 mt-2">
                    <Table className="table-striped"
                        pagination={false}
                        columns={columns}
                        dataSource={updatedhistory}
                        rowKey={(record: any) => record.id}
                        onChange={handleTableChange}
                    />
                </div>
                <HistoryDetail data={updatedhistory} />

                <Pagination count={Math.ceil(updateCount/10)} page={page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                        setPage(value);
                        handleChange(value);
                    }} style={{ marginTop: 10 }} 
                />

                {isError?.flag && <AlertPopup open={isError?.flag} setClose={(flg: boolean)=>setIsError({...isError, flag: flg}) } />}

            </div>
        </DocumentTitle>
    )
};

export default TemplateHistory;
