import React from 'react';

import '../../assets/css/detail.css';
import Methods from 'Utilities/CommonMethods';

const HistoryDetail = ({ data }: any): JSX.Element => {

    return (
        <div id="see_history" className="modal custom-modal fade " role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Template Detail</h5>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div id="emp_profile" className="pro-overview tab-pane fade show active">
                            <div className="row">
                                <div className="col-md-12 d-flex">
                                    <div className="card profile-box flex-fill">
                                        <div className="card-body">
                                            <h3 className="card-title"> <a className="edit-icon" data-bs-toggle="modal" data-bs-target="#personal_info_modal"><i className="fa fa-pencil" /></a></h3>
                                            <ul className="personal-info">
                                                <li>
                                                    <div className="title">ID</div>
                                                    <div className="text">{data?.id}</div>
                                                </li>
                                                <li>
                                                    <div className="title">user_id</div>
                                                    <div className="text">{data?.user_id}</div>
                                                </li>
                                                <li>
                                                    <div className="title">File</div>
                                                    <div className="text"><a href={data?.file_name} target="_blank">{data?.file_name}</a></div>
                                                </li>
                                                <li>
                                                    <div className="title">Type</div>
                                                    <div className="text">{data?.type}</div>
                                                </li>

                                                <li>
                                                    <div className="title">Created Date</div>
                                                    <div className="text">{ Methods.getEasternTimeZone(data?.created_on, 'MMM Do YYYY') }</div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HistoryDetail;
