import axios from "axios";
import { store } from "Redux/Store/index";
import { toast } from "react-toastify";
import { API_UNAUTHORISED } from "Definations/Constants";
import { onAdminLogout } from "Redux/Slices/Auth";

export default async function setAuthorizationToken(token: string) {
  if (token) {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = `${token}`;
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
  }
}

export function setAxiosInterceptors() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      toast.error(error.message);
      if (error?.response?.status === API_UNAUTHORISED) {
        store.dispatch(onAdminLogout(undefined));
      }
      return error.message;
    }
  );
}
