interface IRoutes {
    login: string;
    slash: string;
    dashboard: string;
    triage: string;
    users: string;
    templateHistory: string;
    reportedConstruction: string;
    reportedOccupancy: string;
    customOccupancy: string;
    subscription: string;
    customConstruction: string;
    utils: string;
    signIn: string;
    asteric: string;
    invoice: string;
    empty: string;
}

export const AppRoutes: IRoutes = {
    empty: '',
    slash: '/',
    asteric: '*',
    login: 'login',
    dashboard: 'dashboard',
    users: 'users',
    templateHistory: 'templatehistory',
    reportedConstruction: 'reportedconstruction',
    reportedOccupancy: 'reportedoccupancy',
    customOccupancy: 'customoccupancy',
    customConstruction: 'customconstruction',
    subscription: 'subscription',
    utils: 'utils',
    signIn: 'signin',
    triage: 'triage',
    invoice: 'invoice'
}
