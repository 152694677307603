/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import ApiUrls from "Definations/ApiUrls";
import { Tooltip, Table } from 'antd';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AlertDialogSlide from 'Shared/SharedModal';
import { handleUpdatedFilter, SortingData} from 'Utilities/Pagination';
import Methods from "Utilities/CommonMethods";
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import {useFetchDeleteReportedConstruction} from "Hooks/UseFetchSOV";
import Pagination from "@mui/material/Pagination";
import {AlertPopup} from "Components/Alert/Alert";
import ScreenFilter from 'Components/ScreenFilter/ScreenFilter';
import { TextField } from '@mui/material';
import { IScreenFilter } from 'Utilities/Interfaces';
import { useDebounce } from 'Hooks/UseDebounce';

const mockData = [];

for (let i = 0; i < 20; i++) {
    mockData.push({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
    });
}

const AllReportedConstruction = (props: any) => {

    const [page, setPage] = React.useState<number>(1);
    const [selectedFilters, setSelectedFilters] = React.useState<IScreenFilter[]>([]);
    const [search, setSearch] = useState<string>('');
    const [id, SetId] = useState();
    const [updatedconstruction, setUpdatedConstruction] = React.useState<any>([]);
    const [modal, setModal] = useState(false);
    const [updateCount, setUpdateCount] = React.useState<number>(10);
    const [isError, setIsError] = React.useState<{ data: any, flag: boolean }>({ data: '', flag: false });
    const [sort, setSort] = React.useState<any>();

    const { triggered } = useFetchDeleteReportedConstruction(id);
    const debouncedValue: any = useDebounce(selectedFilters.length > 0 ? selectedFilters[0].value : '', 1000);

    useEffect(() => {
        if (debouncedValue) {
            handleFilters(debouncedValue);
        } else {
            handleChange(1);   
        }
    }, [debouncedValue]);

    const columns = [
        {
            title: 'Company',
            dataIndex: 'company_name',
            // sorter: (a: any, b: any) => a.id - b.id,
        },
        {
            title: 'Construction',
            dataIndex: 'construction',
        },
        {
            title: 'Construction Name',
            dataIndex: "construction_code_id",
            render: (p: any, record: any) => <p>{record?.construction_code_id?.construction}</p>
        },
        {
            title: 'Construction Type',
            dataIndex: "construction_code_id",
            render: (p: any, record: any) => <p>{record?.construction_code_id?.code_type}</p>
        },
        {
            title: 'Construction Code',
            dataIndex: "construction_code_id",
            render: (p: any, record: any) => <p>{record?.construction_code_id?.code}</p>
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            sorter: true,
            render: (p: any, record: any) => Methods.getEasternTimeZone(record?.created_on)
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            sorter: true,
            render: (p: any, record: any) => <p>{p}</p>
        },
        {
            title: 'Mapping Count',
            dataIndex: 'count',
            render: (p: any, record: any) => <p>{p}</p>
        },
        {
            title: 'Match Percentage',
            dataIndex: 'percentage',
            render: (p: any, record: any) => <p>{`${p}%`}</p>
        },
        {
            title: 'Action',
            render: (p: any, record: any) => (
                <div className="dropdown dropdown-action text-end">
                    <Tooltip placement="right" color="red" title="Delete">
                        <a onClick={() => deleterow(record?.id)}><DeleteOutline /></a>
                    </Tooltip>

                </div >

            ),
        }
    ]

    const deleterow = (id: any): void => {
        setModal(true);
        SetId(id);
    }

    const actionDelete = (): void => {
        triggered();

        setTimeout(()=>{
            handleChange(page);
            setModal(false);
        },500);
    };

    const handleTableChange = (newPagination: any, filters: any, sorter: any): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        const obj = {page, filter, filterValue};
        setSort(sorter);

        SortingData(sorter, ApiUrls.allReportedCONSTRUCTION, setUpdatedConstruction, obj, (err: any) => (setIsError(err)) );
    };

    const handleFilters = React.useCallback((value: string): void => {
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        setPage(1);

        if(filter){
            handleUpdatedFilter(value, setUpdatedConstruction, ApiUrls.allReportedCONSTRUCTION, page, value ? filter : '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort, undefined );
        }
    }, [page, selectedFilters, sort]);

    const handleChange = React.useCallback((pg: number): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';

        handleUpdatedFilter(filterValue, setUpdatedConstruction, ApiUrls.allReportedCONSTRUCTION, pg, filterValue ? filter : '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort  );
    }, [selectedFilters, sort]);

    return (
        <DocumentTitle condition={true} title="Reported Construction">
            <div className="page-wrapper">
            
            {modal && <AlertDialogSlide data="Are you sure you want to delete" title="Confirm Delete" setmodal={(value: boolean) => setModal(value)} actionDelete={actionDelete}></AlertDialogSlide>}
            
            <ScreenFilter 
                dropdownArray={Methods.getScreenFilters([1, 2])} selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters} onSearch={(txt) => handleFilters(txt)} totalRows={updateCount}
            >
                <TextField 
                    variant="outlined" size="small" value={search} name="occupancy" 
                    onChange={(e) => setSearch(e.target.value)} label="Search" sx={{ width: 250 }}
                />
            </ScreenFilter>                

            <div className="table-responsive shadow bg-white p-2 mt-2">
                <Table className="table-striped"
                    pagination={false}
                    columns={columns}
                    dataSource={updatedconstruction}
                    rowKey={record => record.id}
                    onChange={handleTableChange}
                />
            </div>

             <Pagination
                count={Math.ceil(updateCount/10)} style={{ marginTop: 10 }}
                page={page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                    setPage(value);
                    handleChange(value);
                }}
            />

                {isError?.flag && <AlertPopup open={isError?.flag} setClose={(flg: boolean)=>setIsError({...isError, flag: flg}) } />}
            </div>
        </DocumentTitle>
    );
}

export default AllReportedConstruction

