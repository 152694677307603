import * as React from 'react';

interface IDocumentTitle {
    condition: boolean;
    title: string;
    children: JSX.Element;
}

export const DocumentTitle:React.FC<IDocumentTitle> = ({ condition, title, children }) => {
    React.useEffect(() => {
        document.title = title;
    }, [condition, title]);

    return children;
};