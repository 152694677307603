import * as React from "react";

import {Route, Routes} from "react-router-dom";
import {AppRoutes} from "./Routes";
import Dashboard from "Pages/Dashboard/Dashboard";
import Users from "Pages/Users/Users";
import TemplateHistory from "Pages/TemplateHistory/TemplateHistory";
import AllReportedConstruction from "Pages/ReportedConstruction/ReportedConstruction";
import ReportedOccupancy from "Pages/ReportedOccupancy/ReportedOccupancy";
import CustomOccupancy from "Pages/CustomOccupancy/CustomOccupancy";
import CustomConstruction from "Pages/CustomConstruction/CustomConstruction";
import Utils from "Pages/Utils";
import Subscription from "../Pages/Subscription/Subscription";
import { Redirect } from "./Redirect";
import Methods from "Utilities/CommonMethods";
import TriageDashboard from "Pages/TriageDashboard/TriageDashboard";
import InvoiceReport from "Pages/InvoiceReport/InvoiceReport";

interface IPrivateRoutes{
    isLogin: boolean;
}

const PrivateRoutes:React.FC<IPrivateRoutes> = ({ isLogin }) => {

    if(!isLogin || !Methods.getToken()) {
        return <Redirect to={AppRoutes.slash} />
    }

    return (
        <Routes>
            <Route path={`/${AppRoutes.dashboard}`} element={<Dashboard />} />
            <Route path={`/${AppRoutes.users}`} element={<Users />} />
            <Route path={`/${AppRoutes.templateHistory}`} element={<TemplateHistory />} />
            <Route path={`/${AppRoutes.reportedConstruction}`} element={<AllReportedConstruction />} />
            <Route path={`/${AppRoutes.reportedOccupancy}`} element={<ReportedOccupancy />} />
            <Route path={`/${AppRoutes.customOccupancy}`} element={<CustomOccupancy />} />
            <Route path={`/${AppRoutes.customConstruction}`} element={<CustomConstruction />} />
            <Route path={`/${AppRoutes.subscription}`} element={<Subscription />} />
            <Route path={`/${AppRoutes.utils}`} element={<Utils />} />
            <Route path={`/${AppRoutes.triage}`} element={<TriageDashboard />} />
            <Route path={`/${AppRoutes.invoice}`} element={<InvoiceReport />} />
            {/* <Route path={`/${AppRoutes.asteric}`} element={<div style={{ padding: 5 }}>404 page not found.</div>} /> */}
        </Routes>
    );
};

export default PrivateRoutes;
