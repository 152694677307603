import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Redirect(props: { to: string }): null {

    const navigate = useNavigate();

    useEffect(() => {
        navigate(props.to);
    });

    return null;
}