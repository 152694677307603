import React from "react";
import './Sidebar.scss';

import { useSelector } from "react-redux";
import { toggleStatus } from "Redux/Slices/Sidebar";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionIcon from '@mui/icons-material/Description';
import ConstructionIcon from '@mui/icons-material/Construction';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddCardIcon from '@mui/icons-material/AddCard';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from "react-router-dom";
import {AppRoutes} from "Routes/Routes";
import {Assets} from "Utilities/Assets";

const Sidebar = () => {
    const isOpen: boolean = useSelector(toggleStatus);
    let classes = "sidebar h-100 d-flex flex-column";
    classes += isOpen ? " expanded" : "";

    const navLinks = [
        { id: 1, path: AppRoutes.dashboard, name: "Dashboard", icon: <DashboardIcon /> },
        { id: 2, path: AppRoutes.users, name: "Users", icon: <GroupIcon /> },
        { id: 3, path: AppRoutes.templateHistory, name: "Templates History", icon: <DescriptionIcon /> },
        { id: 4, path: AppRoutes.reportedConstruction, name: "Reported Construction", icon: <ConstructionIcon /> },
        { id: 5, path: AppRoutes.reportedOccupancy, name: "Reported Occupancy", icon: <ApartmentIcon /> },
        { id: 6, path: AppRoutes.subscription, name: "Subscription", icon: <AddCardIcon /> },
        { id: 7, path: AppRoutes.triage, name: "Triage", icon: <PageviewIcon /> },
        { id: 7, path: AppRoutes.invoice, name: "Invoice", icon: <AssessmentIcon /> }
    ];

    return (
        <>
            <div className={classes}>
                <div className="bar-header">
                    <div className="brand py-3 px-2">
                        <NavLink className="when-closed" to={`/${AppRoutes.dashboard}`}>
                            <img
                                src={Assets.brandLogo}
                                alt="Brand logo"
                                className="img-fluid"
                            />
                        </NavLink>
                        <NavLink className="when-opened" to={`/${AppRoutes.dashboard}`}>
                            <img
                                src={Assets.brandLogo}
                                alt="Brand logo"
                                className="img-fluid"
                            />
                        </NavLink>
                    </div>
                </div>
                <div className="links-wrapper flex-grow-1 d-flex flex-column justify-content-between">
                    <div className="navigations">
                        <ul className="nav flex-column">
                            {navLinks.map((link) => {
                                return (
                                    <li key={link.name} className="navigation-link">
                                        <Tooltip
                                            title={link.name}
                                            placement="right"
                                            color="secondary"
                                            arrow
                                        >
                                            <NavLink className={({ isActive }) => isActive ? 'active-link' : undefined} to={link.path}>
                                                <div className="d-flex link-content align-items-center">
                                                    <div className="icon">{link.icon}</div>
                                                    <div className="link-name when-opened">
                                                        <p className="mb-0">{link.name}</p>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </Tooltip>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Sidebar;
