import React, { useEffect, useState } from 'react';

import { Table, Tooltip} from 'antd';
import { Button, Pagination, IconButton } from '@mui/material';
import { useDispatch } from "react-redux";
import { AppDispatch } from 'Redux/Store/index';
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Dialog from "@mui/material/Dialog";
import SOVProgressItem from "./SOVProgressItem";
import { handleUpdatedFilter, SortingData} from "Utilities/Pagination";
import ApiUrls from "Definations/ApiUrls";
import Methods from "Utilities/CommonMethods";
import {IProcessWorkbook, IScreenFilter} from "Utilities/Interfaces";
import {AlertPopup} from "Components/Alert/Alert";
import DateTimeRangePicker, { ISelectionRange } from 'Components/DateRange/DateRange';
import { getDashboardAnalytics } from 'Redux/Slices/DashboardSlice';
import ScreenFilter from 'Components/ScreenFilter/ScreenFilter';
import { toast } from 'react-toastify';

const mockData = [];
for (let i = 0; i < 20; i++) {
    mockData.push({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
    });
}

const SOVProgressTable = () => {

    const dispatch: AppDispatch = useDispatch();

    const [page, setPage] = React.useState<number>(1);
    const [selectedFilters, setSelectedFilters] = React.useState<IScreenFilter[]>([]);
    const [rowClick, setRowClick] = useState<{ data: any, active: boolean }>({ data: Object, active: false });
    const [workbook, setWorkbook] = React.useState<IProcessWorkbook[]>([]);
    const [updateCount, setUpdateCount] = React.useState<number>(10);
    const [sort, setSort] = React.useState<any>();
    const [isApplyFilter, setIsApplyFilter] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<{ data: any, flag: boolean }>({ data: '', flag: false });
    const [selectionRange, setSelectionRange] = React.useState<ISelectionRange>({
        startDate: undefined, endDate: undefined, key: 'selection'
    });

    useEffect(() => {
        handleFilters();
    }, []);    

    const columns = [
        { title: 'Company', dataIndex: 'company_name'},
        { title: 'SOV', dataIndex: 'workbook_name', sorter: true },
        { title: 'Location Count', dataIndex: "location_count", sorter: true },
        { title: 'TIV', dataIndex: "tiv", sorter: true },
        { title: 'Status', dataIndex: "status", sorter: true },
        { title: 'Created By', dataIndex: 'created_by', sorter: true },
        { title: 'Created On', dataIndex: 'created_on', sorter: true,
            render: (text: string, record: any) => (
            <div> { Methods.getEasternTimeZone(text) } </div>
        )},
        { title: 'Action',
            render: (p: any, record: any) => (
                <div className="grid-actions">
                    <Tooltip placement="right" color="blue" title="View detail">
                        <IconButton onClick={() => setRowClick({data: record, active: true}) } color="secondary">
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        }
    ];

    const getAllFilters = React.useCallback((): string => {
        let filters = '';

        selectedFilters.forEach((ff) => {
            if (ff.value) {
                filters = filters + `&${ff.apiKey}=${ff.value}`;
            }
        });

        if (selectionRange.startDate !== undefined && selectionRange.endDate !== undefined) {
            const startDate = Methods.getDateByFormat(selectionRange?.startDate as any, 'YYYY-MM-DD');
            const endDate = Methods.getDateByFormat(selectionRange?.endDate as any, 'YYYY-MM-DD');
            filters = filters + `&created_on_after=${startDate}&created_on_before=${endDate}`;
        };

        return filters;
    }, [selectionRange, selectedFilters]);

    const handleTableChange = React.useCallback((newPagination: any, filters: any, sorter: any) => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        const sortOrder = sorter === 'created_by' ? 'created_by__email' : sorter;
        const obj = {page, filter, filterValue};

        setSort(sortOrder);
        SortingData(sortOrder, ApiUrls.workbookHistories, setWorkbook, obj, (err: any) => (setIsError(err)) )
    }, [page, selectedFilters]);

    const handleFilters = React.useCallback(() => {
        let isFiltered: string = getAllFilters();

        handleUpdatedFilter(undefined, setWorkbook, ApiUrls.workbookHistories, page, '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort, isFiltered );
        dispatch(getDashboardAnalytics(!isFiltered ? undefined : `?${isFiltered.slice(1)}`));
    }, [page, dispatch, sort, getAllFilters]);

    const resetStates = (): void => {
        setSelectedFilters([]);
        setSelectionRange({ startDate: undefined, endDate: undefined, key: 'selection' });
        setPage(1);

        setTimeout(() => {
            handleFilters();
        }, 500);
    }

    return (
        <DocumentTitle title='SOV Progress' condition={true}>
            <div className="page-wrapper">
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '4px', alignItems: 'flex-start' }}>
                    <ScreenFilter 
                        dropdownArray={Methods.getScreenFilters([1, 2, 6])} selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters} onSearch={(txt) => resetStates()} isDashboard totalRows={updateCount}
                    >
                        <DateTimeRangePicker 
                            setSelectionRange={(arg: ISelectionRange | undefined) => {
                                if (arg !== undefined) {
                                    setPage(1);
                                    setSelectionRange(arg);
                                }
                            }} marginTop={0}
                            selectionRange={selectionRange}
                        />
                    </ScreenFilter>
                    <Button onClick={() => {
                        if (getAllFilters()) {
                            setIsApplyFilter(!isApplyFilter);
                            handleFilters();
                        } else {
                            toast.error("Please apply filters!");
                        }
                    }} size="small" variant="outlined" sx={{ height: 37, marginLeft: '15px', marginTop: '4px' }}> Apply </Button>
                </div>

                {/* Page Content */}
                <div className="table-responsive shadow bg-white p-2 mt-2">
                    <Table className="table-striped"
                           pagination={false}
                           columns={columns}
                           dataSource={workbook}
                           rowKey={record => record.id}
                           onChange={handleTableChange}
                    />
                </div>

                <Pagination
                    count={Math.ceil(updateCount/10)} style={{ marginTop: 10 }}
                    page={page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                        setPage(value);
                        setTimeout(() => {
                            handleFilters();
                        }, 1000);
                    }} 
                />

                <Dialog className='popup-window' onClose={()=>setRowClick({...rowClick, active: false })} open={rowClick.active}>
                    <SOVProgressItem key={rowClick.data.id} SOV={rowClick.data} />
                </Dialog>

                {isError?.flag && <AlertPopup open={isError?.flag} setClose={(flg: boolean)=>setIsError({...isError, flag: flg}) } />}
            </div>
        </DocumentTitle>
    );
}
export default SOVProgressTable
