import React, { useEffect } from 'react';

import {Table} from 'antd';
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import Pagination from "@mui/material/Pagination";
import { handleUpdatedFilter, SortingData} from "Utilities/Pagination";
import ApiUrls from "Definations/ApiUrls";
import {IScreenFilter, ISubscriptions} from "Utilities/Interfaces";
import Methods from "Utilities/CommonMethods";
import ScreenFilter from 'Components/ScreenFilter/ScreenFilter';
import { useDebounce } from 'Hooks/UseDebounce';

const mockData = [];
for (let i = 0; i < 20; i++) {
    mockData.push({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
    });
}

const Subscription = (props: any) => {

    const [page, setPage] = React.useState<number>(1);
    const [selectedFilters, setSelectedFilters] = React.useState<IScreenFilter[]>([]);
    const [subscription, setSubscription] = React.useState<ISubscriptions[]>([]);
    const [sort, setSort] = React.useState<any>();
    const [totalCount, setTotalCount] = React.useState<number>(10);
    
    const debouncedValue: any = useDebounce(selectedFilters.length > 0 ? selectedFilters[0].value : '', 1000);

    const columns = [
        {title: 'ID', dataIndex: 'id', sorter: (a: any, b: any) => a.id - b.id},
        {title: 'Company', dataIndex: 'company_name'},
        {title: 'Status', dataIndex: 'status', sorter: (a: any, b: any) => a.id - b.id},
        {title: 'Plan', dataIndex: 'plan_name', sorter: (a: any, b: any) => a.id - b.id},
        {title: 'Location', dataIndex: 'location_used',
            render: (text: string, record: any) => (
                <div> { `${record.location_used}/${record.location_limit} (${Math.round((record.location_used/record.location_limit)*100)}%)` } </div>
            )
        },
        { title: 'Hazard Hub', dataIndex: 'hazard_hub_used'},
        {title: 'Geocode', dataIndex: 'geocode_used'},
        {title: 'Start', dataIndex: 'start_date', sorter: (a: any, b: any) => a.id - b.id,
            render: (text: string, record: any) => (
                <div> { Methods.getEasternTimeZone(text) } </div>
            )
        },
        {title: 'End', dataIndex: 'end_date', sorter: (a: any, b: any) => a.id - b.id,
            render: (text: string, record: any) => (
                <div> { Methods.getEasternTimeZone(text) } </div>
            )
        }
    ];

    useEffect(() => {
        if (debouncedValue) {
            handleFilters(debouncedValue);
        } else {
            handleChange(1);   
        }
    }, [debouncedValue]);

    const handleChange = React.useCallback((pg: number): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';

        handleUpdatedFilter(filterValue, setSubscription, ApiUrls.subscriptions, pg, filterValue ? filter : '', true, (flag: number)=>(setTotalCount(flag)));
    }, [selectedFilters]);

    const handleTableChange = (newPagination: any, filters: any, sorter: any): void => {
        setSort(sorter);
        SortingData(sorter, ApiUrls.subscriptions, setSubscription);
    };

    const handleFilters = React.useCallback((value: string): void => {
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        setPage(1);

        if(filter){
            handleUpdatedFilter(value, setSubscription, ApiUrls.subscriptions, page, value ? filter : '', true, (flag: number)=>(setTotalCount(flag)));
        }
    }, [page, selectedFilters]);

    return (
        <DocumentTitle title='Subscription' condition={true}>
            <div className="page-wrapper">
                <ScreenFilter 
                    dropdownArray={Methods.getScreenFilters([2, 3, 4])} setSelectedFilters={setSelectedFilters}
                    selectedFilters={selectedFilters} onSearch={(txt) => handleFilters(txt)} totalRows={subscription.length}
                />
                
                {/* Page Content */}
                <div className="table-responsive shadow bg-white p-2 mt-2">
                    <Table 
                        className="table-striped"
                        pagination={false}
                        columns={columns}
                        dataSource={subscription}
                        rowKey={record => record.id}
                        onChange={handleTableChange}
                    />
                </div>

                <Pagination count={Math.ceil(totalCount/10)} page={page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                    setPage(value);
                    handleChange(value);
                }} style={{ marginTop: 10 }} />
            </div>
        </DocumentTitle>
    );
}
export default Subscription
