/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { Table, Input, Tooltip } from 'antd';
import { useDispatch } from "react-redux";
import ApiUrls from "Definations/ApiUrls";
import axios from 'axios';
import { AppDispatch } from 'Redux/Store/index';
import { setSpinner } from 'Redux/Slices/Metadata';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AlertDialogSlide from 'Shared/SharedModal';
import { SortingData } from 'Utilities/Pagination';
import Methods from "Utilities/CommonMethods";
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";

const mockData = [];
for (let i = 0; i < 20; i++) {
    mockData.push({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
    });
}

const CutomConstruction = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [modal, setmodal] = useState<boolean>(false);
    const [data, setData] = useState<any>({});
    const [id, Setid] = useState();
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        dispatch(setSpinner(true));
        axios.get(`${process.env.REACT_APP_BASE_URL}${ApiUrls.customConstruction}`, {
            headers: {
                Authorization: `Token ${Methods.getToken()}`
            }
        }).then(res => {
            dispatch(setSpinner(false));
            if (res.data) {
                setData(res.data);
                dispatch(setSpinner(false));
            } else {
                setData(res.data.result);
                dispatch(setSpinner(false));
            }
        }).catch(e => {
            console.log(e);
            dispatch(setSpinner(false));
        });
    }, []);


    const deleterow = (id: any): void => {
        setmodal(true);
        Setid(id);
    };

    const handleFilters = (value: any): void => {
        // handleFilter(value, setData, Custom_Construction);
    };

    const actionDelete = () => {
        setLoading(true)
        axios.delete(`${process.env.REACT_APP_BASE_URL}${ApiUrls.customConstructionDelete}${id}/`, {
            headers: {
                Authorization: `Token ${Methods.getToken()}`
            }
        }).then(res => {
            dispatch(setSpinner(false));
            if (res.data) {
                setLoading(false);
                setmodal(false);
            } else {
                setLoading(false);
                setmodal(false);
            }
        }).catch(e => {
            console.log(e);
            setLoading(false);
            setmodal(false);
        });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a: any, b: any) => a.id - b.id,
        },
        {
            title: 'Construction',
            dataIndex: 'construction',
        },
        {
            title: 'Construction Name',
            dataIndex: "construction_code_id",
            render: (p: any, record: any) => <p>{record?.construction_code_id?.construction}</p>
        },
        {
            title: 'Construction Type',
            dataIndex: "construction_code_id",
            render: (p: any, record: any) => <p>{record?.construction_code_id?.code_type}</p>
        },
        {
            title: 'Construction Code',
            dataIndex: "construction_code_id",
            render: (p: any, record: any) => <p>{record?.construction_code_id?.code}</p>
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            sorter: true,
            render: (p: any, record: any) => <p> {Methods.getEasternTimeZone(record?.created_on) }</p>
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            render: (p: any, record: any) => <p>{p}</p>
        },
        {
            title: 'Mapping Count',
            dataIndex: 'count',
            render: (p: any, record: any) => <p>{p}</p>
        },
        {
            title: 'Match Percentage',
            dataIndex: 'percentage',
            render: (p: any, record: any) => <p>{`${p}%`}</p>
        },
        {
            title: 'Action',
            render: (p: any, record: any) => (
                <div className="dropdown dropdown-action text-end">
                    <Tooltip placement="right" color="red" title="Delete">
                        <a onClick={() => deleterow(record?.id)}><DeleteOutline /></a>
                    </Tooltip>
                </div >

            ),
        }
    ]

    // const SortingData = (data: any, url: any) => {
    //     setLoading2(true)
    //     const sign = data.order == 'ascend' ? '-' : '';
    //     axios.get(`${process.env.REACT_APP_BASE_URL}${url}?ordering=${sign}created_on`, {
    //         headers: {
    //             Authorization: `Token ${getToken()}`
    //         }

    //     }).then(res => {
    //         dispatch(setSpinner(false));
    //         if (res.data) {
    //             setData(res.data)
    //             console.log(res.data)
    //             setLoading1(false)
    //         } else {
    //             setData(res.data.result)
    //             setLoading1(false)
    //         }
    //     }).catch(e => {
    //         console.log(e);
    //         setLoading1(false)
    //     })


    // }

    const handleTableChange = (newPagination: any, filters: any, sorter: any): void => {
        SortingData(sorter, ApiUrls.customConstruction, setData);
    }

    return (
        <DocumentTitle title='Construction - LMS' condition={true}>
            <div className="page-wrapper">
            {modal && <AlertDialogSlide data="Are you sure you want to delete" title="Confirm Delete" setmodal={(value: boolean) => setmodal(value)} actionDelete={actionDelete}></AlertDialogSlide>}

            <div style={{ width: '400px' }}>
                <Input.Search placeholder="Created By" size="large" loading={false} allowClear onPressEnter={handleFilters} onSearch={handleFilters} />
            </div>
            <div className="table-responsive shadow bg-white p-2">
                <Table className="table-striped"
                    pagination={false}
                    columns={columns}
                    dataSource={data?.results}
                    onChange={handleTableChange}
                />
            </div>
            <div className='paginate'>
            </div>
        </div>
        </DocumentTitle>
    );
}
export default CutomConstruction

