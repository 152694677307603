import * as React from 'react';

import axios from 'axios';
import { Table, Tooltip, Input } from 'antd';
import { useDispatch } from "react-redux";
import ApiUrls from "Definations/ApiUrls";
import { AppDispatch } from 'Redux/Store/index';
import { setSpinner } from 'Redux/Slices/Metadata';
import AlertDialogSlide from 'Shared/SharedModal';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { SortingData } from 'Utilities/Pagination';
import Methods from "Utilities/CommonMethods";
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";

const mockData = [];
for (let i = 0; i < 20; i++) {
    mockData.push({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
    });
}

const CustomOccupancy = (props: any) => {

    const [loading, setLoading] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any>({});
    const [modal, setmodal] = React.useState<boolean>(false)
    const [id, Setid] = React.useState();
    const dispatch: AppDispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setSpinner(true));
        axios.get(`${process.env.REACT_APP_BASE_URL}${ApiUrls.customOccupancy}`, {
            headers: {
                Authorization: `Token ${Methods.getToken()}`
            }
        }).then(res => {
            dispatch(setSpinner(false));
            if (res.data) {
                setData(res.data);
                dispatch(setSpinner(false));
            } else {
                setData(res.data.result);
                dispatch(setSpinner(false));
            }
        }).catch(e => {
            console.log(e);
            dispatch(setSpinner(false));
        });
    }, []);

    const deleterow = (id: any): void => {
        setmodal(true);
        Setid(id);
    }

    const actionDelete = (): void => {
        setLoading(true);
        axios.delete(`${process.env.REACT_APP_BASE_URL}${ApiUrls.customOccupancyDelete}${id}/`, {
            headers: {
                Authorization: `Token ${Methods.getToken()}`
            }
        }).then(res => {
            dispatch(setSpinner(false));
            if (res.data) {
                setLoading(false);
                setmodal(false);
            } else {
                setLoading(false);
                setmodal(false);
            }
        }).catch(e => {
            console.log(e);
            setLoading(false);
            setmodal(false);
        });
    }

    const handleTableChange = (newPagination: any, filters: any, sorter: any): void => {
        console.log(newPagination, filters, sorter);
        SortingData(sorter, ApiUrls.customOccupancy, setData);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a: any, b: any) => a.id - b.id,
        },
        {
            title: 'Occupancy',
            dataIndex: 'occupancy',
        },
        {
            title: 'Occupancy Name',
            dataIndex: "occupancy_code_id",
            render: (p: any, record: any) => <p>{record?.occupancy_code_id?.occupancy}</p>
        },
        {
            title: 'Occupancy Type',
            dataIndex: "occupancy_code_id",
            render: (p: any, record: any) => <p>{record?.occupancy_code_id?.code_type}</p>
        },
        {
            title: 'Occupancy Code',
            dataIndex: "occupancy_code_id",
            render: (p: any, record: any) => <p>{record?.occupancy_code_id?.code}</p>
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            sorter: true,
            render: (p: any, record: any) => <p> { Methods.getEasternTimeZone(p) } </p>
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            render: (p: any, record: any) => <p>{p}</p>
        },
        {
            title: 'Mapping Count',
            dataIndex: 'count',
            render: (p: any, record: any) => <p>{p}</p>
        },
        {
            title: 'Match Percentage',
            dataIndex: 'percentage',
            render: (p: any, record: any) => <p>{`${p}%`}</p>
        },
        {
            title: 'Action',
            render: (p: any, record: any) => (

                <div className="dropdown dropdown-action text-end">
                    <Tooltip placement="right" color="red" title="Delete">
                        <a onClick={() => deleterow(record?.id)}><DeleteOutline /></a>
                    </Tooltip>
                </div>
            ),
        }
    ];
    
    const handleFilters = (value: any): void => {
        // handleFilter(value, setData, Custom_Occupancy);
    }

    return (
        <DocumentTitle title='Occupancy - LMS' condition={true}>
            <div className="page-wrapper">
                {modal && <AlertDialogSlide data="Are you sure you want to delete" title="Confirm Delete" setmodal={(value: boolean) => setmodal(value)} actionDelete={actionDelete}></AlertDialogSlide>}
                
                <div style={{ width: '400px' }} >
                    <Input.Search placeholder="Created By" enterButton size="large" loading={false} allowClear onPressEnter={handleFilters} onSearch={handleFilters} />
                </div>

                {/* Page Content */}
                <div className="table-responsive shadow bg-white p-2">
                    <Table className="table-striped"
                        pagination={false}
                        columns={columns}
                        dataSource={data.results}
                        rowKey={record => record.id}
                        onChange={handleTableChange}
                    />
                </div>
                <div className='paginate'>
                </div>
            </div>
        </DocumentTitle>
    );
}
export default CustomOccupancy

