import React, {useEffect, useState} from 'react';
import './UsersDataGrid.scss';

import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Dialog from '@mui/material/Dialog';

import { useDispatch } from 'react-redux';
import { onGetUserActivity } from 'Redux/Slices/UsersSlice';
import { AppDispatch } from 'Redux/Store/index';
import UserActivity from 'Components/UserActivity/UserActivity';
import { handleUpdatedFilter, SortingData} from "Utilities/Pagination";
import ApiUrls from "Definations/ApiUrls";
import Methods from "Utilities/CommonMethods";
import Pagination from "@mui/material/Pagination";
import {AlertPopup} from "Components/Alert/Alert";
import { Table, Tooltip } from 'antd';
import ScreenFilter from 'Components/ScreenFilter/ScreenFilter';
import { IScreenFilter } from 'Utilities/Interfaces';
import { useDebounce } from 'Hooks/UseDebounce';

const UsersDataGrid: React.FC = () => {

    const dispatch: AppDispatch = useDispatch();
    const [page, setPage] = React.useState<number>(1);
    const [selectedFilters, setSelectedFilters] = React.useState<IScreenFilter[]>([]);
    const [user, setUser] = React.useState<any>([]);
    const [isShowActivity, setIsShowActivity] = useState(false);
    const [updateCount, setUpdateCount] = React.useState<number>(10);
    const [isError, setIsError] = React.useState<{ data: any, flag: boolean }>({ data: '', flag: false });
    const [sort, setSort] = React.useState<any>();

    const debouncedValue: any = useDebounce(selectedFilters.length > 0 ? selectedFilters[0].value : '', 1000);

    useEffect(() => {
        if (debouncedValue) {
            handleFilters(debouncedValue);
        } else {
            handleChange(1);   
        }
    }, [debouncedValue]);

    const columns = [
        {
            dataIndex: 'company_name',
            title: 'Company',
        },
        {
            dataIndex: 'email',
            title: 'Email',
            sorter: true
        },
        {
            dataIndex: 'first_name',
            title: 'First Name',
            sorter: (a: any, b: any) => a.id - b.id
        },
        {
            dataIndex: 'last_name',
            title: 'Last Name',
            sorter: (a: any, b: any) => a.id - b.id
        },
        { title: 'Created On', dataIndex: 'created_on', sorter: (a: any, b: any) => a.id - b.id,
            render: (text: string, record: any) => (
                <div> { Methods.getEasternTimeZone(text) } </div>
            )
        },
        { 
            dataIndex: 'is_active',
            title: 'Active',
            render: (text: boolean) => <div> {text ? "Yes" : "No"} </div>
        },
        {
        title: 'Action',
        render: (p: any, record: any) => (
            <div className="grid-actions">
                <Tooltip placement="right" color="blue" title="View log">
                    <IconButton onClick={() => onViewLog(record.email)} color="info">
                        <VisibilityOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
        )}
    ];

    const onViewLog = (email: string): void => {
        dispatch(onGetUserActivity({ page: 1, user_email: email }));
        setIsShowActivity(true);
    }

    const closeActivity = (): void => {
        setIsShowActivity(false);
    };

    const handleTableChange = (newPagination: any, filters: any, sorter: any): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        const sortOrder = sorter === 'email' ? 'user_id__email' : sorter;
        const obj = { page, filter, filterValue };

        setSort(sortOrder);
        SortingData(sortOrder, ApiUrls.allUsers, setUser, obj, (err: any) => (setIsError(err)));        
    };

    const handleFilters = React.useCallback((value: string): void => {
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        setPage(1);
        if(filter){
            setTimeout(() => {
                handleUpdatedFilter(value, setUser, ApiUrls.allUsers, page, value ? filter : '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort, undefined );
            }, 500);
        }
    }, [page, selectedFilters, sort]);

    const handleChange = React.useCallback((pg: number): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        handleUpdatedFilter(filterValue, setUser, ApiUrls.allUsers, pg, filterValue ? filter : '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort);
    }, [selectedFilters, sort]);

    return (
        <React.Fragment>
            <ScreenFilter 
                dropdownArray={Methods.getScreenFilters([1, 2])} selectedFilters={selectedFilters} 
                onSearch={(txt) => handleFilters(txt)} totalRows={updateCount} setSelectedFilters={setSelectedFilters}
            />
            
            <div className="table-responsive shadow bg-white p-2 mt-2">
                <Table className="table-striped"
                    pagination={false}
                    columns={columns}
                    dataSource={user}
                    rowKey={record => record.id}
                    onChange={handleTableChange}
                />
                <Dialog className='popup-window' onClose={closeActivity} open={isShowActivity}>
                    <UserActivity />
                </Dialog>
            </div>

            <Pagination count={Math.ceil(updateCount / 10)} page={page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPage(value);
                handleChange(value);
            }} style={{ marginTop: 10 }} />

            {isError?.flag && <AlertPopup open={isError?.flag} setClose={(flg: boolean)=>setIsError({...isError, flag: flg}) } />}
        </React.Fragment>
    )
};

export default UsersDataGrid;
