import React, { useState } from 'react';

import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const FileUtility = () => {
    const [fileNames, setFileNames] = useState([]);
    const onSelectFile = (e: any) => {
        let _fileNames: any = [];
        // let fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        Array.from(e.target.files).forEach((file: any) => {
            const reader = new FileReader();
            _fileNames.push(file.name);
            let fileName = file.name.split('.');
            reader.addEventListener('load', () => {
                const bstr = reader.result;
                const wb = XLSX.read(bstr, { type: 'array', cellStyles: true });
                const finalfile = XLSX.writeFile(wb, fileName[0] + '.xlsx', { bookType: 'xlsx', bookSST: false, type: 'binary', cellStyles: true });
                FileSaver.saveAs(finalfile);
            })
            reader.readAsArrayBuffer(file);
        });
        console.log(window.location);
        // Array.from(e.target.files).forEach((file: any) => {

        //     const reader = new FileReader();
        //     _fileNames.push(file.name);
        //     let fileName = file.name.split('.');
        //     reader.addEventListener('load', () => {
        //         console.log(file);
        //         FileSaver.saveAs(file, fileName[0] + '.xlsx');
        //     });
        //     reader.readAsArrayBuffer(file);
        // });
        setFileNames(_fileNames);
    }
    return (
        <>

            <div className="control-group">
                <FormLabel component="legend">Convert .xls to xlsx</FormLabel><br />
                <div className="control-wrapper d-flex justify-content-between">
                    <div className="w-33">
                        <div>
                            <input accept="application/vnd.ms-excel" multiple hidden id="icon-button-file" type="file" onChange={onSelectFile} />
                            <label htmlFor="icon-button-file" className='file-upload-label'>
                                <IconButton color="primary" aria-label="upload picture" component="span" >
                                    <CloudUploadIcon />
                                </IconButton>
                                {fileNames.length > 0 ? fileNames.join(', ') : 'Select xls files.'}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default FileUtility;