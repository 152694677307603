import React from "react";
import './Header.scss';

import { useDispatch, useSelector } from "react-redux";
import { togglebar, toggleStatus } from "Redux/Slices/Sidebar";
import { AppDispatch } from "Redux/Store/index";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import UserMenu from "Components/UserMenu/UserMenu";
import {AppRoutes} from "Routes/Routes";

const Header = () => {
    const isOpen = useSelector(toggleStatus);
    const dispatch: AppDispatch = useDispatch();

    return (
        <>
            <div className="app-header d-flex p-2 align-items-center">
                <div className="flex-grow-1 d-flex align-items-center">
                    <button className="toggler" onClick={() => dispatch(togglebar())}>
                        {isOpen ? <MenuOpenIcon /> : <MenuIcon />}
                    </button>
                    &nbsp;
                    <div className="page-title" style={{ marginLeft: 12 }}>
                        <p className="mb-0">{document.title}</p>
                    </div>
                </div>
                <div className="mx-3" style={{ fontWeight: 'bold' }}>Eastern Timezone</div>
                <div>
                    <div className="user-info mr-10">
                        <Tooltip
                            title={'Utils'}
                            placement="bottom"
                            color="secondary"
                            arrow
                        >
                            <div className="icon">
                                <Link to={`/${AppRoutes.utils}`}>
                                    <AppRegistrationIcon></AppRegistrationIcon>
                                </Link>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div>
                    <UserMenu />
                </div>
            </div>
        </>
    )
};

export default Header;