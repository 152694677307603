import * as React from "react";

import { Autocomplete, TextField, Chip } from "@mui/material";
import { IScreenFilter } from "Utilities/Interfaces";

interface IAutoComplete {
  arr: IScreenFilter[];
  selected: IScreenFilter[];
  setSelected: (arg: IScreenFilter[]) => void;
  onDeleteChip: (option: any) => void;
  mainLabel: string;
  minWidth?: number
}

const AutoCompleteMultiple: React.FC<IAutoComplete> = ({
    arr,
    selected,
    setSelected,
    onDeleteChip,
    mainLabel,
    minWidth
}) => {
  const handleChange = React.useCallback(
    (value: IScreenFilter[]) => {
      setSelected(value);
    },
    [selected]
  );

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={arr}
      getOptionLabel={(option) => option.label}
      value={selected as any[] | any}
      size="small"
      clearIcon={false}
      filterSelectedOptions
      limitTags={3}
      style={{ marginBottom: '4px', minWidth: minWidth ? `${minWidth}px` : 'auto' }}
      isOptionEqualToValue={(option, value) => {
        return option.label === value.label;
      }}
      onChange={(_event, value: any[] | any | string[]) => {
        handleChange(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={mainLabel}
          placeholder="Choose"
          size="small"
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option: IScreenFilter, index: number) => (
          <Chip
            label={option.label} size="small" variant="filled" className={getTagProps({ index }).className}
            tabIndex={getTagProps({ index }).tabIndex} key={getTagProps({ index }).key} disabled={false}
            onDelete={(e) => {
              getTagProps({ index }).onDelete(e);
              onDeleteChip(option);
            }}
          />
        ))
      }
      sx={{ width: "100%" }}
    />
  );
};

export default AutoCompleteMultiple;
