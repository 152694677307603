class Url {
    public readonly adminLogin = '/admin/users/login/';
    public readonly adminLogout = '/user/logout/';
    public readonly getUsers = '/admin/users';
    public readonly getUserActivity ='/admin/audit-log/';
    public readonly getDashboardAnalytics = '/admin/dashboard/';
    public readonly workbookHistories = '/admin/processed-workbook/';
    public readonly subscriptions = '/admin/subscription-details/';
    public readonly login = "/user/login_with_password/";
    public readonly allUsers = "/admin/users";
    public readonly templateHistory = "/admin/user-template-history";
    public readonly allReportedCONSTRUCTION = "/admin/reported-construction";
    public readonly allReportedOccupancy = "/admin/reported-occupancy";
    public readonly reportedConstructionDelete = "/admin/reported-construction/";
    public readonly reportedOccupancyDelete = "/admin/reported-occupancy/";
    public readonly customOccupancy = "/admin/custom-reported-occupancy";
    public readonly customConstruction = "/admin/custom-reported-construction";
    public readonly customOccupancyDelete = "/admin/custom-reported-occupancy/";
    public readonly customConstructionDelete = "/admin/custom-reported-construction/";
    public readonly hazardHub = "/admin/company/";
    public readonly companiesList = "/admin/company-names";
    public readonly triageListing = "/admin/triage/";
    public readonly companyInvoice = "/admin/generate-invoice/";
}

const ApiUrls = new Url();
export default ApiUrls;
