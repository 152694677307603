import { useState, useEffect, useMemo, useCallback } from 'react';
import { getProcessedSOVsList, getAProcessedSOV, getCompaniesList, getTriageDataListing, getInvoiceByCompany } from 'Services/DashboardService';
import { ICompanyInvoice, IProcessWorkbook, ITriageDataListItem } from 'Utilities/Interfaces';
import {
    deleteReportedConstruction, deleteReportedOccupancy,
    getReportedConstruction,
    getReportedOccupancy,
    getTemplateHistory,
    getUserList
} from "../Services/UserService";
import {setSpinner} from "../Redux/Slices/Metadata";
import {AppDispatch, store} from "../Redux/Store";
import {useDispatch} from "react-redux";
import { API_UNAUTHORISED } from 'Definations/Constants';
import { onLogout } from 'Redux/Slices/Auth';

export const useFetchProcessedSOVs = (page: number): {
    loading: boolean, totalCount: number, allWorkbooks: IProcessWorkbook[]
} => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [allWorkbooks, setAllWorkbooks] = useState<IProcessWorkbook[]>([]);

    useEffect(() => {
        fetchData();
    }, [page]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        dispatch(setSpinner(true));
        const results = await getProcessedSOVsList(page);

        if (results.status === API_UNAUTHORISED) {
            store.dispatch(onLogout());
            return;
        }

        setAllWorkbooks(results?.data?.results ?? []);
        setTotalCount(results?.data?.count);
        setLoading(false);
        dispatch(setSpinner(false));
    }, [page, dispatch]);

    return useMemo(() => {
        return {
            loading, totalCount, allWorkbooks
        }
    }, [loading, totalCount, allWorkbooks]);
}

export const useFetchAProcessedSOV = (workbookId: number | null): {
    loading: boolean, workbook: IProcessWorkbook | undefined
} => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [workbook, setWorkbook] = useState<IProcessWorkbook | undefined>(undefined);

    useEffect(() => {
        async function getInitials(){
            setLoading(true);
            dispatch(setSpinner(true));
            const results = await getAProcessedSOV(workbookId);

            if (results.status === API_UNAUTHORISED) {
                store.dispatch(onLogout());
                return;
            }

            setWorkbook(results?.data ?? []);
            setLoading(false);
            dispatch(setSpinner(false));
        }

        if(workbookId){
            getInitials();
        }
    }, [workbookId]);

    return useMemo(() => {
        return { loading, workbook }
    }, [loading, workbook]);
}

export const useFetchUserList = (page: number): {
    loading: boolean, totalCount: number, users: any[]
} => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        async function getInitials(){
            setLoading(true);
            dispatch(setSpinner(true));
            const results = await getUserList();

            if (results.status === API_UNAUTHORISED) {
                store.dispatch(onLogout());
                return;
            }

            setUsers(results?.data?.results ?? []);
            setTotalCount(results?.data?.count);
            setLoading(false);
            dispatch(setSpinner(false));
        }

        getInitials();
    }, []);

    return useMemo(() => {
        return {
            loading, totalCount, users
        }
    }, [loading, totalCount, users]);
};

export const useFetchTemplateHistory = (page: number): {
    loading: boolean, totalCount: number, history: any[]
} => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [history, setHistory] = useState<any[]>([]);

    useEffect(() => {
        async function getInitials(){
            setLoading(true);
            dispatch(setSpinner(true));
            const results = await getTemplateHistory(page);

            if (results.status === API_UNAUTHORISED) {
                store.dispatch(onLogout());
                return;
            }

            setHistory(results?.data?.results ?? []);
            setTotalCount(results?.data?.count);
            setLoading(false);
            dispatch(setSpinner(false));
        }

        getInitials();
    }, []);

    return useMemo(() => {
        return {
            loading, totalCount, history
        }
    }, [loading, totalCount, history]);
}

export const useFetchReportedConstruction = (page: number): {
    loading: boolean, totalCount: number, construction: any[], refreshRender: () => void
} => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [construction, setConstruction] = useState<any | undefined>(undefined);

    async function getInitials(){
        setLoading(true);
        dispatch(setSpinner(true));
        const results = await getReportedConstruction(page);

        if (results.status === API_UNAUTHORISED) {
            store.dispatch(onLogout());
            return;
        }

        setConstruction(results?.data?.results ?? []);
        setTotalCount(results?.data?.count);
        setLoading(false);
        dispatch(setSpinner(false));
    }

    useEffect(() => {
        getInitials();
    }, []);

    const refreshRender = (): void => {
        getInitials();
    };

    return useMemo(() => {
        return {
            loading, totalCount, construction, refreshRender
        }
    }, [loading, totalCount, construction]);
}

export const useFetchDeleteReportedConstruction = (id: number | undefined): {
    loading: boolean, construction: any | undefined, triggered: () => void
} => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [construction, setConstruction] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (id) {
            getInitials();
        }
    }, [id]);

    async function getInitials() {
        setLoading(true);
        dispatch(setSpinner(true));
        const results = await deleteReportedConstruction(id);

        if (results.status === API_UNAUTHORISED) {
            store.dispatch(onLogout());
            return;
        }

        setConstruction(results?.data ?? []);
        setLoading(false);
        dispatch(setSpinner(false));
    }

    const triggered = (): void => {
        getInitials();
    };

    return useMemo(() => {
        return {
            loading, construction, triggered
        }
    }, [loading, construction]);
}

export const useFetchReportedOccupancy = (page: number): {
    loading: boolean, totalCount: number, occupancy: any[], refreshRender: () => void
} => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [occupancy, setOccupancy] = useState<any | undefined>(undefined);

    async function getInitials(){
        setLoading(true);
        dispatch(setSpinner(true));
        const results = await getReportedOccupancy(page);

        if (results.status === API_UNAUTHORISED) {
            store.dispatch(onLogout());
            return;
        }

        setOccupancy(results?.data?.results ?? []);
        setTotalCount(results?.data?.count);
        setLoading(false);
        dispatch(setSpinner(false));
    }
    const refreshRender = (): void => {
        getInitials();
    };

    useEffect(() => {
        getInitials();
    }, []);

    return useMemo(() => {
        return {
            loading, totalCount, occupancy, refreshRender
        }
    }, [loading, totalCount, occupancy]);
}

export const useFetchDeleteReportedOccupancy = (id: number | undefined): {
    loading: boolean, occupancy: any | undefined, triggered: () => void
} => {
    const dispatch: AppDispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [occupancy, setOccupancy] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (id) {
            getInitials();
        }
    }, [id]);

    async function getInitials() {
        setLoading(true);
        dispatch(setSpinner(true));
        const results = await deleteReportedOccupancy(id);
        
        if (results.status === API_UNAUTHORISED) {
            store.dispatch(onLogout());
            return;
        }

        setOccupancy(results?.data ?? []);
        setLoading(false);
        dispatch(setSpinner(false));
    }

    const triggered = (): void => {
        getInitials();
    };

    return useMemo(() => {
        return {
            loading, occupancy, triggered
        }
    }, [loading, occupancy]);
}

export const useFetchAllCompanies = (): {
    loading: boolean, companiesList: string[]
} => {
    const [loading, setLoading] = useState<boolean>(false);
    const [companiesList, setCompaniesList] = useState<string[]>([]);

    useEffect(() => {
        getInitials();
    }, []);

    async function getInitials() {
        setLoading(true);
        const results: any = await getCompaniesList();

        if (results.status === API_UNAUTHORISED) {
            store.dispatch(onLogout());
            return;
        }

        setCompaniesList(results?.data?.company_names ?? []);
        setLoading(false);
    }

    return useMemo(() => {
        return {
            loading, companiesList
        }
    }, [loading, companiesList]);
}

export const useFetchTriageListing = (): {
    loading: boolean, data: ITriageDataListItem, refresh: (params: string) => void
} => {
    const [loading, setLoading] = useState<boolean>(false);
    const [sovList, setSovList] = useState<ITriageDataListItem>({
        count: 0, next: '', previous: null, results: []
    });

    useEffect(() => {
        getInitials('');
    }, []);

    async function getInitials(params: string) {
        setLoading(true);
        store.dispatch(setSpinner(true));
        const results = await getTriageDataListing(params);

        if (results.status === API_UNAUTHORISED) {
            store.dispatch(onLogout());
            return;
        }

        if (results?.data) {
            setSovList(results?.data);
        }
        if (!results?.data) {
            setSovList({
                count: 0, next: '', previous: null, results: []
            });
        }
        setLoading(false);
        store.dispatch(setSpinner(false));
    }

    const refresh = (params: string): void => {
        getInitials(params);
    }

    return useMemo(() => {
        return {
            loading, data: sovList, refresh
        }
    }, [loading, sovList]);
}

export const useGenerateCompanyInvoice = (params: string): {
    companyInvoice: ICompanyInvoice | null, resetHookState: () => void
} => {
    const [companyInvoice, setCompanyInvoice] = useState<ICompanyInvoice | null>(null);

    useEffect(() => {
        if (params) {
            getInitials(params);
        }
    }, [params]);

    async function getInitials(params: string) {
        store.dispatch(setSpinner(true));
        const results = await getInvoiceByCompany(params);

        if (results.status === API_UNAUTHORISED) {
            store.dispatch(onLogout());
            return;
        }

        if (results?.data) {
            setCompanyInvoice(results?.data as ICompanyInvoice);
        }
        if (!results?.data) {
            setCompanyInvoice(null);
        }
        store.dispatch(setSpinner(false));
    }

    return useMemo(() => {
        return {
            companyInvoice,
            resetHookState: () => setCompanyInvoice(null)
        }
    }, [companyInvoice]);
}