import * as React from 'react';

import { Input } from 'antd';
import DropDown, { AutoComplete } from "Components/Dropdown/DropDown";
import { useFetchAllCompanies } from 'Hooks/UseFetchSOV';
import AutoCompleteMultiple from 'Components/AutoComplete/AutoComplete';
import { IScreenFilter } from 'Utilities/Interfaces';

interface IProps {
    onSearch: (txt: string) => void;
    dropdownArray: IScreenFilter[];
    flexDirection?: any;
    isDashboard?: boolean;
    children?: JSX.Element;
    totalRows?: number;
    selectedFilters: IScreenFilter[]; 
    setSelectedFilters: (arr: IScreenFilter[]) => void;
}

const ScreenFilter: React.FC<IProps> = (props) => {

    const { companiesList } = useFetchAllCompanies();    
    const { selectedFilters, setSelectedFilters, onSearch, dropdownArray, totalRows, flexDirection, isDashboard } = props;

    const onChangeSelectedFilters = React.useCallback((v: string | any, ind: number) => {
        const arr = [...selectedFilters];
        arr[ind] = { ...arr[ind], value: v };
        setSelectedFilters(arr);
    }, [selectedFilters, setSelectedFilters]);

    return (
        <div style={{ display: 'flex', flexDirection: flexDirection ? flexDirection : 'column' }}>
            
            <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                { isDashboard ? (
                    <AutoCompleteMultiple minWidth={250} mainLabel="Filters" onDeleteChip={(item) => console.log(item)} arr={dropdownArray} selected={selectedFilters} setSelected={setSelectedFilters} />
                ) : (
                    <DropDown arr={dropdownArray.map((c) => c.label)} setData={(str: string) => {
                        setSelectedFilters(dropdownArray.filter((f) => f.label === str));
                    }} />
                )}
                <div style={{ textAlign: 'center', minWidth: '170px', fontSize: '16px' }}><span style={{ fontWeight: 'bold' }}>Total Rows: </span>{totalRows ?? 0}</div>
                {props.children}
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                { selectedFilters.map((sf: IScreenFilter, ind: number) => (
                    sf.apiKey === "company_name" ? (
                        <AutoComplete key={ind} arr={companiesList} setData={(txt: string) => onChangeSelectedFilters(txt, ind)} labelKey='Select Company' />
                    ) : (
                        <Input.Search key={ind} value={sf.value} placeholder={`Type ${sf.label}`} enterButton enterKeyHint="search" size="large" loading={false} allowClear 
                            onSearch={(txt) => onSearch(txt)}
                            onChange={(e) => onChangeSelectedFilters(e.target.value, ind)} 
                            style={{ flexShrink: 0, width: '250px', marginRight: '10px' }}
                        />
                    )
                )) }
            </div>
        </div>
    );
}
export default ScreenFilter;
