// HTTP / connection Constants

export const HTTP_GET: string = 'GET';
export const HTTP_POST: string = 'POST';

export const API_SUCCESS: number = 200;
export const API_CREATED: number = 201;
export const API_DELETE: number = 204;
export const API_UNAUTHORISED: number = 401;
export const TEMP_REQUIRED: number = 307;

export const AlertsMsgs = {
    unAuthorized: "Unauthorized access! Please login to continue.",
    somethingWentWrong: "Something went wrong!",
};

export const AppColors = {
    grey: 'rgba(224, 224, 224, 1)'
};
