import React from 'react';

import UsersDataGrid from "Components/UsersDataGrid/UsersDataGrid";
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";

const Users = () => {
    return (
        <DocumentTitle title='Users' condition={true}>
            <UsersDataGrid />
        </DocumentTitle>
    )
};

export default Users;
