import * as React from 'react';

import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import DateTimeRangePicker, { ISelectionRange } from 'Components/DateRange/DateRange';
import { AutoComplete } from "Components/Dropdown/DropDown";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useFetchAllCompanies, useGenerateCompanyInvoice } from 'Hooks/UseFetchSOV';
import { Assets } from 'Utilities/Assets';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AppColors } from 'Definations/Constants';
import Methods from 'Utilities/CommonMethods';
import { Button, TextField, ButtonGroup } from '@mui/material';
import { useReactToPrint } from 'react-to-print';

function SubTitlesDisplay({ title, subTitle, arr, justifyContent }: { title: string | string[]; subTitle: string; arr: string[], justifyContent: any; }): JSX.Element {
    return (
        <Grid container direction="row" justifyContent={justifyContent}>
            <Grid item sx={{ minWidth: '40px' }}>
                { Array.isArray(title) ? title.map((t, ind: number) => {
                    if (ind === 0) {
                        return (
                            <Typography key={ind} variant="subtitle1" component="div" className='font-bold'>
                                {t}
                            </Typography>
                        );
                    }

                    return (
                        <Typography variant="caption" component="div" key={ind}>
                            { t }
                        </Typography>
                    );
                }) : title }
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ border: `1px solid ${AppColors.grey}`, margin: '0px 10px' }}></Divider>
            <Grid item xs={5}>
                <Typography variant="subtitle1" component="div" className='font-bold'>
                    {subTitle}
                </Typography>
                { [...arr].map((str: string, index: number) => (
                    <Typography variant="caption" component="div" key={index}>
                        { str }
                    </Typography>
                )) }
            </Grid>
        </Grid>
    );
}

const InvoiceReport = () => {

    const reportTemplateRef = React.useRef();
    const { companiesList } = useFetchAllCompanies();

    const [params, setParams] = React.useState<string>('');
    const [uniqueBy, setUniqueBy] = React.useState<string>('data');
    const [currentCompany, setCurrentCompany] = React.useState<string>('');
    const [invoiceId, setInvoiceId] = React.useState<string>('');
    const [selectionRange, setSelectionRange] = React.useState<ISelectionRange>({
        startDate: undefined,
        endDate: undefined,
        key: 'selection',
    });

    const { companyInvoice, resetHookState } = useGenerateCompanyInvoice(params);

    const generatePDF = useReactToPrint({
        content: () => reportTemplateRef.current as any,
        documentTitle: `Invoice for ${currentCompany} by ${Methods.getDateByFormat(selectionRange?.startDate as any, 'YYYY-MM-DD')}/${Methods.getDateByFormat(selectionRange?.endDate as any, 'YYYY-MM-DD')}`        
    })

    const generateInvoice = React.useCallback(() => {
        if (currentCompany && selectionRange?.startDate && selectionRange?.endDate && invoiceId && uniqueBy) {
            const SD = Methods.getDateByFormat(selectionRange?.startDate as any, 'YYYY-MM-DD');
            const ED = Methods.getDateByFormat(selectionRange?.endDate as any, 'YYYY-MM-DD');

            setParams(`?from_date=${SD}&to_date=${ED}&company_name=${currentCompany}&invoice_id=${invoiceId}&unique_by=${uniqueBy}`);
        }
    }, [currentCompany, invoiceId, selectionRange, uniqueBy]);

    return React.useMemo(() => {
        const border = { borderRight: '2px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgba(224, 224, 224, 1)' };

        const counts = companyInvoice?.list_data?.totals;
        const invoiceFrom = companyInvoice?.invoice_details?.invoice_from;
        const invoiceFor = companyInvoice?.invoice_details?.invoice_for;
        const invoiceServicesList = companyInvoice?.list_data?.service ?? [];
        const invoiceProductsList = companyInvoice?.list_data?.product ?? [];
    
        return (
            <DocumentTitle title='Invoice / Report' condition={true}>
                
                <Grid container direction="column">

                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={2}>
                            <AutoComplete isAutoWidth={true} arr={companiesList} setData={(txt: string) => setCurrentCompany(txt)} labelKey='Select Company' />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField variant="outlined" size="small" value={invoiceId} name="invoice_id" 
                                onChange={(e) => setInvoiceId(e.target.value)}
                                label="Invoice ID" fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeRangePicker 
                                setSelectionRange={(arg: ISelectionRange | undefined) => {
                                    if (arg !== undefined) {
                                        setSelectionRange(arg);
                                    } else {
                                        setSelectionRange({
                                            startDate: undefined,
                                            endDate: undefined,
                                            key: 'selection',
                                        });
                                    }
                                }}
                                selectionRange={selectionRange} marginTop={0}
                            />
                            <ButtonGroup size="medium" aria-label="small button group" sx={{ marginLeft: 2 }}>
                                { ['Data', 'Name'].map((op) => (
                                    <Button key={op} onClick={() => setUniqueBy(op.toLowerCase())} variant={uniqueBy === op.toLowerCase() ? 'contained' : 'outlined'}>{op}</Button>
                                )) }
                            </ButtonGroup> 
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" sx={{ marginRight: 2 }} onClick={() => {
                                setCurrentCompany('');
                                setSelectionRange({
                                    startDate: undefined,
                                    endDate: undefined,
                                    key: 'selection',
                                });
                                setInvoiceId('');
                                resetHookState();
                            }}>Reset</Button>                                
                            <Button variant="contained" color="primary" sx={{ marginRight: 2 }} onClick={() => generateInvoice()}>Generate Invoice</Button>                                
                            <Button variant="contained" color="primary" onClick={() => companyInvoice && generatePDF()}>Export Pdf</Button>                                
                        </Grid>                        
                    </Grid>
                    <br />

                    <div ref={reportTemplateRef as any}>                    
                        <Card>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item xs={6} sx={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}>  
                                    <img
                                        src={Assets.brandLogo} alt="Brand logo" className="img-fluid" width="175px"
                                    />
                                </Grid>
                                <Grid item xs={6} container direction="row" justifyContent="flex-end">
                                    <Grid item xs={5}>
                                        <Typography variant="h5" component="div" className='font-bold'>
                                            INVOICE
                                        </Typography>
                                    </Grid>
                                    <SubTitlesDisplay justifyContent="flex-end" arr={[invoiceFrom?.address_line2 ?? "", invoiceFrom?.address_line3 ?? "", invoiceFrom?.address_line4 ?? ""]} title='from' subTitle={invoiceFrom?.address_line1 ?? ""} />
                                </Grid>
                            </Grid>
                            <br /><br />

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item xs={6} sx={{ paddingLeft: 6 }}>
                                    <SubTitlesDisplay justifyContent="flex-start" arr={[companyInvoice?.invoice_details?.issue_date ?? '', companyInvoice?.invoice_details?.due_date ?? '', companyInvoice?.invoice_details?.subject ?? ""]} title={['Invoice ID', 'Issue Date', 'Due Date', 'Subject']} subTitle={companyInvoice?.invoice_details?.invoice_id?.toString() ?? "N/A"} />
                                </Grid>
                                <Grid item xs={6} container direction="row" justifyContent="flex-end">
                                    <SubTitlesDisplay justifyContent="flex-end" arr={[invoiceFor?.address_line2 ?? "", invoiceFor?.address_line3 ?? "", invoiceFor?.address_line4 ?? ""]} title='Invoice For' subTitle={invoiceFor?.address_line1 ?? ""} />
                                </Grid>
                            </Grid>
                            <br /><br />

                            <TableContainer sx={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Table sx={{ minWidth: 650, paddingLeft: 5, paddingRight: 5 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" width="92px" sx={{ ...border, fontSize: '13px' }}>
                                                Item Type
                                            </TableCell>
                                            <TableCell align="left" width="60%" sx={{ ...border, fontSize: '13px' }}>Description</TableCell>
                                            <TableCell align="right" sx={{ ...border, fontSize: '13px' }}>Quantity</TableCell>
                                            <TableCell align="right" sx={{ ...border, fontSize: '13px' }}>Unit Price</TableCell>
                                            <TableCell align="right" sx={{ borderBottom: `2px solid ${AppColors.grey}`, fontSize: '13px' }}>Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { [...invoiceServicesList, ...invoiceProductsList]?.map((item, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ background: index % 2 !== 0 ? 'whitesmoke' : 'transparent' }}
                                            >
                                                <TableCell align="left" width="92px" sx={{ ...border }}>
                                                    { invoiceServicesList.length > index ? "Service" : "Product" }
                                                </TableCell>
                                                <TableCell align="left" width="60%" sx={{ ...border }}>{item.description}</TableCell>
                                                <TableCell align="right" sx={{ ...border }}>{item?.quantity}</TableCell>
                                                <TableCell align="right" sx={{ ...border }}>${item?.unit_price}</TableCell>
                                                <TableCell align="right" sx={{ borderBottom: `2px solid ${AppColors.grey}` }}>${item?.amount}</TableCell>
                                            </TableRow>
                                        )) }                            
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />

                            <Grid container direction="column" sx={{ paddingRight: 5 }}>
                                { [
                                    { caption: 'Subtotal', value: counts?.sub_total ?? 0 },
                                    { caption: `Discount (${counts?.discount_percentage ?? 0}%)`, value: counts?.discount ?? 0 },
                                    { caption: `Tax (${counts?.tax_percentage ?? 0}%)`, value: counts?.tax ?? 0 }
                                ].map(({ caption, value }, ind: number) => (
                                    <Grid item key={ind} container direction="row" justifyContent="flex-end" alignItems="center">
                                        <Typography variant="caption" component="div" sx={{ minWidth: 100, textAlign: 'right' }}>
                                            {caption}
                                        </Typography>
                                        <Typography className='font-bold' variant="subtitle2" component="div" sx={{ minWidth: 100, textAlign: 'right' }}>
                                            ${value}
                                        </Typography>
                                    </Grid>
                                )) }       
                                <br />

                                <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
                                    <Typography className='font-bold' variant="h6" component="div" sx={{ marginRight: 6 }}>
                                        Amount Due
                                    </Typography>
                                    <Typography className='font-bold' variant="h6" component="div">
                                        ${ counts?.amount_due ?? 0 }
                                    </Typography>
                                </Grid>                 
                            </Grid>
                            
                            <Typography variant="subtitle2" component="div" sx={{ paddingLeft: 5, marginBottom: 1, marginTop: 8, fontSize: 12 }}>
                                Make all checks payable to VINAR LLC.
                            </Typography>
                            <Typography variant="subtitle2" component="div" sx={{ paddingLeft: 5, fontSize: 12, marginBottom: 5 }}>
                                THANK YOU FOR YOUR BUSINESS!
                            </Typography>
                        </Card>
                    </div>

                </Grid>            
                            
            </DocumentTitle>
        )
    }, [companyInvoice, selectionRange, companiesList, invoiceId, reportTemplateRef, uniqueBy, generatePDF, generateInvoice]);
};

export default InvoiceReport;
