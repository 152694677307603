import { createAsyncThunk, createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { StoreDef } from 'Redux/Store/index';
import { adminLogin, adminLogout } from "Services/AuthService";
import {toast} from "react-toastify";
import {IAuthSlice} from "Utilities/Interfaces";
import Methods from "Utilities/CommonMethods";
import { API_SUCCESS, API_UNAUTHORISED } from "Definations/Constants";
import { AppRoutes } from "Routes/Routes";

export const onAdminLogin = createAsyncThunk("@admin_onlogin", async (data: any, thunkApi) => {
    try {
        const response = await adminLogin(data);
        const errMsg: any = response.data;
        if (response.status === API_SUCCESS) {
            toast.success('Logged in successfully!');
            return { data: response.data, isSuccess: true }
        } else {
            toast.error(errMsg.message);
            return { isSuccess: false, error: errMsg.message }
        }

    } catch (error:any) {
        toast.error("Something went wrong!");
        return thunkApi.rejectWithValue(error);
    }
});

export const onAdminLogout = createAsyncThunk("@admin_onlogout", async (data: any, thunkApi) => {
    try {
        const response = await adminLogout(data);
        const errMsg: any = response.data;
        if (response.status === API_UNAUTHORISED) {
            Methods.clearStorages();
            return { isSuccess: false, error: errMsg?.message };
        }
        if (response.status === API_SUCCESS) {
            toast.success('Logged out successfully!');
            localStorage.removeItem("accessToken");
            window.location.href = AppRoutes.slash;
            return { data: response.data, isSuccess: true }
        } else {
            toast.error(errMsg.message);
            return { isSuccess: false, error: errMsg.message }
        }

    } catch (error:any) {
        toast.error("Something went wrong!");
        return thunkApi.rejectWithValue(error);
    }
});

const initialState: IAuthSlice = {
    isAuthorised: false,
    currentUser: null,
    status: "idle",
    token: '',
    loginError: ''
};

const loginSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        onLogout: (state) => {
            localStorage.removeItem("accessToken");
            
            state.status = "idle";
            state.currentUser = initialState.currentUser;
            state.isAuthorised = initialState.isAuthorised;
            state.token = initialState.token;
        },
        onAutoLogin: (state, action) => {
            state.token = action.payload;
            state.isAuthorised = true;
        },
        setLoginStatus: (state, action) => {
            state.status = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(onAdminLogin.pending, (state) => {
            state.status = "loading";
            state.loginError = '';
        })
        builder.addCase(onAdminLogin.fulfilled, (state, action) => {
            state.status = "idle";
            state.loginError = action.payload?.error;
            if (action.payload?.isSuccess) {
                localStorage.setItem("accessToken", action.payload.data.token);
                sessionStorage.setItem("accessToken", action.payload.data.token);

                state.isAuthorised = action.payload?.isSuccess ? true : false;
                state.token = action.payload.data.token || '';
                state.currentUser = action.payload.data ?? undefined;

                window.location.href = AppRoutes.dashboard;
            }
        })
        builder.addCase(onAdminLogin.rejected, (state) => {
            state.status = "idle";
            state.isAuthorised = false;
        });

        // logout
        builder.addCase(onAdminLogout.pending, (state) => {
            state.status = "loading";
            state.loginError = '';
        })
        builder.addCase(onAdminLogout.fulfilled, (state) => {
            state.status = "idle";
            state.currentUser = initialState.currentUser;
            state.isAuthorised = initialState.isAuthorised;
            state.token = initialState.token;
        })
        builder.addCase(onAdminLogout.rejected, (state) => {
            state = {
                ...initialState,
            };
        });
    }
});

const selfSelect = (state: StoreDef) => state.auth;

export const tokenSelector = createDraftSafeSelector(selfSelect, state => state.token);
export const loginErrSelector = createDraftSafeSelector(selfSelect, state => state.loginError);
export const isAdminAuthorised = createDraftSafeSelector(selfSelect, state => state.isAuthorised);
export const currentUserSelector = createDraftSafeSelector(selfSelect, state => state.currentUser);
export const isLoadingSelector = createDraftSafeSelector(selfSelect, state => state.status);

export const { onLogout, onAutoLogin, setLoginStatus } = loginSlice.actions;
export default loginSlice.reducer;