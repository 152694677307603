import { createAsyncThunk, createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { API_SUCCESS, API_UNAUTHORISED } from "Definations/Constants";
import { StoreDef } from 'Redux/Store/index';
import { getUserList, getUserActivity } from "Services/UserService";

export const onGetUserList = createAsyncThunk('@admin_getuserList', async (_, thunkApi) => {
    try {
        const response = await getUserList();
        const errMsg: any = response.data;
        if (response.status === API_UNAUTHORISED) {
            localStorage.clear();
            sessionStorage.clear();
            return { isSuccess: false, error: errMsg?.message };
        }
        else if (response.status === API_SUCCESS) {
            return { data: response.data, isSuccess: true }
        } else {
            return { isSuccess: false, error: errMsg.message }
        }

    } catch (error: any) {
        return thunkApi.rejectWithValue(error);
    }
});

export const onGetUserActivity = createAsyncThunk('@admin_getuserActivity', async (data: { page: number, user_email: string }, thunkApi) => {
    try {
        const response = await getUserActivity(data);
        const errMsg: any = response.data;
        if (response.status === API_UNAUTHORISED) {
            localStorage.clear();
            sessionStorage.clear();
            return { isSuccess: false, error: errMsg?.message };
        }
        else if (response.status === API_SUCCESS) {
            return { data: response.data, isSuccess: true }
        } else {
            return { isSuccess: false, error: errMsg.message }
        }

    } catch (error: any) {
        return thunkApi.rejectWithValue(error);
    }
});

type UserActivity = {
    count: number;
    next: string | null,
    previous: string | null,
    results: any[]  
}

const activity: UserActivity = {
    count: 0,
    next: '',
    previous: '',
    results: []
}

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        status: "idle",
        error: '',
        users: [],
        data: {results: [], count: 0, next: '', previous: ''},
        userActivity: activity
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(onGetUserList.pending, (state) => {
            state.status = "loading";
        })
        builder.addCase(onGetUserList.fulfilled, (state, action) => {
            state.status = "idle";
            state.data = action.payload.data;
            state.users = action.payload.data.results;
        })
        builder.addCase(onGetUserList.rejected, (state) => {
            state.status = "idle";
        });

        // onGetUserActivity
        builder.addCase(onGetUserActivity.pending, (state) => {
            state.status = "loading";
        })
        builder.addCase(onGetUserActivity.fulfilled, (state, action) => {
            state.status = "idle";
            state.userActivity = action.payload.data;
        })
        builder.addCase(onGetUserActivity.rejected, (state) => {
            state.status = "idle";
        });
    }
});


const selfSelect = (state: StoreDef) => state.users;

export const usersSelector = createDraftSafeSelector(selfSelect, state => state.users);
export const userDataSelector = createDraftSafeSelector(selfSelect, state => state.data);
export const userActivitySelector = createDraftSafeSelector(selfSelect, state => state.userActivity);
export const usersLoader = createDraftSafeSelector(selfSelect, state => state.status);


export default usersSlice.reducer;
