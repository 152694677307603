import {createDraftSafeSelector, createSlice} from "@reduxjs/toolkit";
import { StoreDef } from 'Redux/Store/index';

const sidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        isOpen: false,
    },
    reducers: {
        togglebar: (state) => {
            state.isOpen = !state.isOpen
        }
    },
});

const selfSelect = (state: StoreDef) => state.sidebar;
export const toggleStatus = createDraftSafeSelector(selfSelect, state => state.isOpen);

export const { togglebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;