import React, { FC, ComponentType, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate} from "react-router-dom";
import {isAdminAuthorised} from "Redux/Slices/Auth";
import {AppDispatch} from "Redux/Store";
import PublicLayout from "./PublicLayout";
import SignIn from "Components/SignIn/SignIn";
import {AppRoutes} from "../Routes/Routes";
import Methods from 'Utilities/CommonMethods';

export interface IWithAuthentication {
    navigate?: (to: string, options: any) => void;
    dispatch?: AppDispatch;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const withAuthentication = <ComponentProps extends Props>(WrappedComponent: ComponentType<ComponentProps & Props>, redirectRoute: string) => {

    const WithProtectedWrapper:FC<ComponentProps & Props> = (props) => {

        const isLogin = useSelector(isAdminAuthorised);
        const dispatch: AppDispatch = useDispatch();

        const navigate = useNavigate();

        useEffect(() => {
            if(!isLogin || !Methods.getToken()) {
                navigate(AppRoutes.slash);
            }
            else {
                navigate(redirectRoute === AppRoutes.slash ? AppRoutes.dashboard : redirectRoute); 
            }
        }, [isLogin]);

        if(!isLogin || !Methods.getToken()){
            return (
                <PublicLayout isLogin={false}>
                    <SignIn isLogin={false} />
                </PublicLayout>
            );
        }

        return <WrappedComponent {...props} dispatch={dispatch} navigate={navigate} />;
    };

    return WithProtectedWrapper;

};

export default withAuthentication;