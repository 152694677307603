import { configureStore, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER } from "redux-persist";

import AuthReducer from 'Redux/Slices/Auth';
import SidebarReducer from 'Redux/Slices/Sidebar';
import MetadataReducer from 'Redux/Slices/Metadata';
import DashboardReducer from 'Redux/Slices/DashboardSlice';
import UsersReducer from 'Redux/Slices/UsersSlice';

const rootReducer = combineReducers({
    auth: AuthReducer,
    sidebar: SidebarReducer,
    metadata: MetadataReducer,
    dashboard: DashboardReducer,
    users: UsersReducer
});

const persistConfig = {
    key: 'root',
    version: 1,
    storage: storage,
    whitelist: ['auth'],
    reconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // serializableCheck: false,
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type StoreDef = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;