import * as React from "react";

import axios from "axios";
import { setSpinner } from "Redux/Slices/Metadata";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { store } from "Redux/Store";
import Methods from "Utilities/CommonMethods";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name: string, personName: string[], theme: any) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function itemRender(current: any, type: string, originalElement: any): JSX.Element {
  if (type === "prev") {
    return <span>Previous</span>;
  }
  if (type === "next") {
    return <span>Next</span>;
  }
  return originalElement;
}

export function onShowSizeChange(current: number, pageSize: number): void {
  // console.log(current, pageSize);
}


export function FilterChip() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Created By</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Created by" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export const handleUpdatedFilter = (value: any, setData: any, url: string, page: number, filter: string, isResult: boolean, totalCount?: any, isError?: any, sort?: any, completeFilter?: any) => {
  store.dispatch(setSpinner(true));
  const isFilter = completeFilter ? completeFilter : filter ? `&${filter}=${value}` : '';
  const sign = sort?.order === "descend" ? "-" : "";
  const isSort = sort?.column ? `&ordering=${sign}${sort?.column?.dataIndex}` : '';

  axios
      .get(`${process.env.REACT_APP_BASE_URL}${url}?page=${page}&page_size=10${isFilter}${isSort}`, {
        headers: {
          Authorization: `Token ${Methods.getToken()}`,
        },
      })
      .then((res) => {
        store.dispatch(setSpinner(false));
        if (res.data) {
          setData(isResult ? res.data.results : res.data);
          totalCount(res?.data?.count);
          isError({data: '', flag:false});
        }
      })
      .catch((e) => {
        isError({data: e, flag:true});
        store.dispatch(setSpinner(false));
      });
};


export const SortingData = (data: any, url: string, setData: any, obj?: any, isError?: any) => {
  // store.dispatch(setSpinner(true));
  const {page, filter, filterValue} = obj;

  const sign = data.order === "descend" ? "-" : "";
  const isFilter = filter ? `&${filter}=${filterValue}` : ''
  axios
    .get(`${process.env.REACT_APP_BASE_URL}${url}?ordering=${sign}${data.column.dataIndex}&page=${page}&page_size=10${isFilter}`, {
      headers: {
        Authorization: `Token ${Methods.getToken()}`,
      },
    })
    .then((res) => {
      if (res.data) {
        setData(res.data.results);
        isError({data: '', flag:false});
      }
    })
    .catch((e) => {
      isError({data: e, flag:true});
    });
};
