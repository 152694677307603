import axios from "axios";
import ApiUrls from "Definations/ApiUrls";
import Methods from "Utilities/CommonMethods";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function dashboardAnalytics(isFiltered?: any): Promise<any> {
    const _token = Methods.getToken();
    const url = isFiltered ? `${ApiUrls.getDashboardAnalytics}${isFiltered}` : ApiUrls.getDashboardAnalytics;
    return axios.get(`${BASE_URL}${url}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export async function getProcessedSOVsList(page: number): Promise<any> {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.workbookHistories}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        params: { page, page_size: 10 },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export async function getSubsciptionList(page: number): Promise<any> {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.subscriptions}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        params: { page },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export async function getCompaniesList(): Promise<{ data: { company_names: string[] } }> {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.companiesList}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export async function getAProcessedSOV(workbookId: number | null): Promise<any> {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.workbookHistories}/${workbookId}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
};

export async function getTriageDataListing(params?: string): Promise<any> {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.triageListing}${params}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
};

export async function getInvoiceByCompany(params?: string): Promise<any> {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.companyInvoice}${params}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
};
