import * as React from "react";

import {Grid} from "@material-ui/core";
import { Chip, Stack, Box, Typography } from '@mui/material';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { IProcessWorkbook } from 'Utilities/Interfaces';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetchAProcessedSOV } from "Hooks/UseFetchSOV";
import DownloadingIcon from '@mui/icons-material/Downloading';
import Methods from "Utilities/CommonMethods";

interface ISOVProgressItem{
    SOV: IProcessWorkbook;
}

const SOVProgressItem:React.FC<ISOVProgressItem> = ({ SOV }) => {

    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [workbookId, setWorkbookId] = React.useState<number | null>(null);
    const { loading, workbook } = useFetchAProcessedSOV(workbookId);

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };
    
    const refreshOrReload = (): void => {
        setWorkbookId(SOV?.id);
    };

    const downloadFile = (): void => {
        
    };

    const data: IProcessWorkbook = workbook ?? SOV;

    return (
        <Box sx={{ p: 3 }}>
            <Grid container direction="column">
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={1} nonLinear alternativeLabel>
                    {['Initialization', 'In Process', 'Completed'].map((label: string, index: number) => {
                        let stepProps: { completed: boolean; } = { completed: false };
                        let buttonProps: { optional: JSX.Element; } = { optional: <div></div> };

                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }

                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton
                                    onClick={() => console.log()}
                                    completed={
                                        (index === 1 && label.trim() === data.status.trim()) ? (index < 2) :
                                        (index === 2 && label.trim() === data.status.trim()) ? (index < 3)
                                        : (index === 0)
                                    }
                                    {...buttonProps}
                                >
                                    {label}
                                </StepButton>
                            </Step>
                            );
                        })}
                    </Stepper>
                </Box>
                <Grid container>
                    { Object.entries(data).map(([key, value]) => (
                        !key.includes('all_work_sheets') &&
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={key}>
                            <Typography sx={{ textTransform: 'capitalize' }}> <b> { key.split('_')?.join(' ') } </b> </Typography>
                            <Typography>{ key.includes('_on') ? Methods.getEasternTimeZone(value) : (value || value === 0) ? value : 'N/A' }</Typography>
                            <br />
                        </Grid>
                    )) }
                </Grid>
                <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
                    <Chip disabled={loading} label="Load" onClick={refreshOrReload} size="small" sx={{ padding: '8px 12px' }} />
                    <Chip disabled={loading} label="Refresh" avatar={<RestartAltIcon />} sx={{ padding: '8px 12px' }} variant="outlined" size="small" onClick={refreshOrReload} />
                    { data?.status === "Completed" && <Chip disabled={loading} label="Download File" avatar={<DownloadingIcon />} sx={{ padding: '8px 12px' }} variant="outlined" size="small" onClick={downloadFile} /> }
                    { loading && <CircularProgress color="success" size={17} sx={{ marginLeft: 4 }} /> }
                </Stack>
            </Grid>
        </Box>
    );
};

export default SOVProgressItem;
