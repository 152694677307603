import { createAsyncThunk, createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { API_SUCCESS, API_UNAUTHORISED } from "Definations/Constants";
import { StoreDef } from 'Redux/Store/index';
import { dashboardAnalytics } from "Services/DashboardService";

export const getDashboardAnalytics = createAsyncThunk("@admin_dashboardAnalytics", async (isFiltered: any, thunkApi) => {
    try {
        const response = await dashboardAnalytics(isFiltered);
        const errMsg: any = response.data;
        if (response.status === API_UNAUTHORISED) {
            localStorage.clear();
            sessionStorage.clear();
            return { isSuccess: false, error: errMsg?.message };
        } 
        else if (response.status === API_SUCCESS) {
            return { data: response.data, isSuccess: true };
        } else {
            return { isSuccess: false, error: errMsg.message };
        }

    } catch (error: any) {
        return thunkApi.rejectWithValue(error);
    }
});

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        status: "idle",
        analytics: {
            user_count: 0,
            processed_sov_count: 0,
            average_location_count: 0,
            unique_sov_count: 0
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDashboardAnalytics.pending, (state) => {
            state.status = "loading";
        })
        builder.addCase(getDashboardAnalytics.fulfilled, (state, action) => {
            state.status = "idle";
            state.analytics = action.payload.data;
        })
        builder.addCase(getDashboardAnalytics.rejected, (state) => {
            state.status = "idle";
        });
    },
});

const selfSelect = (state: StoreDef) => state.dashboard;

export const analyticsSelector = createDraftSafeSelector(selfSelect, state => state.analytics);
export const dashboardLoader = createDraftSafeSelector(selfSelect, state => state.status);


export default dashboardSlice.reducer;