import * as React from 'react';

import { Table, Tooltip} from 'antd';
import { AnalyticCard } from "Components/Analytics/Analytics";
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import {Grid, IconButton} from "@material-ui/core";
import Methods from 'Utilities/CommonMethods';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ArticleIcon from '@mui/icons-material/Article';
import { TriageFilters } from './TriageFilters';
import { useFetchTriageListing } from 'Hooks/UseFetchSOV';
import Pagination from "@mui/material/Pagination";
import { useDebounce } from 'Hooks/UseDebounce';

const TriageDashboard = (): JSX.Element => {

    const [params, setParams] = React.useState<string>('');
    const searchQuery = useDebounce(params, 1500);
    const [page, setPage] = React.useState<number>(1);

    const { loading, data, refresh } = useFetchTriageListing();

    const tableColumns = [
        { title: 'Company', dataIndex: 'company_name'},
        { title: 'SOV', dataIndex: 'workbook_name', sorter: true },
        { title: 'Location Count', dataIndex: "location_count", sorter: true },
        { title: 'TIV', dataIndex: "tiv", sorter: true },
        { title: 'Created On', dataIndex: 'created_on', sorter: true,
            render: (text: string, record: any) => (
            <div> { Methods.getEasternTimeZone(text) } </div>
        )},
        { title: 'Action',
            render: (p: any, record: any) => (
                <div className="grid-actions">
                    <Tooltip placement="right" color="blue" title="View detail">
                        <IconButton onClick={() => console.log("")} color="secondary" disabled>
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        }
    ];

    React.useEffect(() => {
        if (searchQuery) {
            refresh(`?page=${page}${searchQuery ? `&${searchQuery}` : ''}`);
        }
    }, [searchQuery]);

    const handleTableChange = React.useCallback((newPagination: any, filters: any, sorter: any) => {
        console.log({ newPagination, filters, sorter });
    }, []);

    return (
        <DocumentTitle title='Triage Dashboard' condition={true}>
            <Grid container direction="row">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="analytics-container d-flex flex-wrap mb-2">
                        <AnalyticCard loading={loading} icon={<ArticleIcon />} count={data.count} title='Processed SOVs' />
                    </div>
                    <TriageFilters getFilters={(url: string) => setParams(url)} refreshListing={() => refresh('')} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="page-wrapper">
                        <div className="table-responsive shadow bg-white p-2 mt-2">
                            <Table className="table-striped"
                                pagination={false}
                                columns={tableColumns}
                                dataSource={data.results}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>

                    <Pagination
                        count={Math.ceil(data.count/10)} style={{ marginTop: 10 }}
                        page={page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                            setPage(value);
                            refresh(`?page=${value}${searchQuery ? `&${searchQuery}` : ''}`);
                        }} 
                    />
                </Grid>
            </Grid>
        </DocumentTitle>
    );
};

export default TriageDashboard;
