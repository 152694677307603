import * as React from "react";

import Analytics from "Components/Analytics/Analytics";
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import {Grid} from "@material-ui/core";
import SOVProgressTable from "./SOVProgressTable";

const Dashboard = () => {
    return (
        <DocumentTitle title='Dashboard' condition={true}>
            <Grid container direction="row">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Analytics />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <SOVProgressTable />
                </Grid>
            </Grid>
        </DocumentTitle>
    )
};

export default Dashboard;
