import React from "react";
import './App.scss';

import PrivateRoutes from 'Routes/PrivateRoutes';
import { useSelector } from "react-redux";
import Toastify from "Shared/ToastNotification";
import Spinner from "Shared/Spinner";
import { spinnerStatusSelector } from "Redux/Slices/Metadata";
import PrivateLayout from "Layouts/PrivateLayout";
import withAuthentication from 'Layouts/WithAuthentication';
import { isAdminAuthorised } from "Redux/Slices/Auth";

const App = () => {
  const enableSpinner = useSelector(spinnerStatusSelector);
  const isLogin = useSelector(isAdminAuthorised);

  return (
    <div className="app-wrapper">
      <PrivateLayout>
        <PrivateRoutes isLogin={isLogin} />
      </PrivateLayout>
      <Toastify />
      {enableSpinner ? <Spinner /> : null}
    </div>
  );
}

export default withAuthentication(App, window.location.pathname);
