import React from "react";
// import {Assets} from "Utilities/Assets";

interface IPublicLayout{
    children: JSX.Element;
    isLogin: boolean;
}

const PublicLayout:React.FC<IPublicLayout> = (props): JSX.Element => {
    return (
        <div className="public-wrapper">
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col">
                        <div className="auth-form-container d-flex flex-column justify-content-center">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PublicLayout;