import React, { useEffect, useState, useCallback } from "react";

import {useDispatch, useSelector} from "react-redux";
import { isLoadingSelector, loginErrSelector, onAdminLogin, setLoginStatus } from "Redux/Slices/Auth";
import {Button, TextField} from '@mui/material';
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import {AppDispatch} from "Redux/Store";

interface ISignIn {
    isLogin: boolean;
}

const SignIn:React.FC<ISignIn> = () => {

    const dispatch: AppDispatch = useDispatch();

    const isLoginError = useSelector(loginErrSelector);
    const isLoading: string = useSelector(isLoadingSelector);

    const [account, setAccount] = useState<{ email: string, password: string }>({ email: "", password: "" });
    const [isReset, setIsReset] = useState<boolean>(false);

    useEffect(() => {
        dispatch(setLoginStatus("idle"));
    }, []);

    const onInputChange = useCallback((e: any) => {
        setAccount({ ...account, [e.target.name]: e.target.value });
    }, [account]);

    const onReset = (): void => {};
    const onLogin = (): void => {
        dispatch(onAdminLogin(account));
    };

    return (
        <DocumentTitle condition={true} title="SOV Wizard | Admin">
            <React.Fragment>
                <h3 className="text-center">Sign In.</h3>
                <hr />
                <form noValidate autoComplete="off">
                {isLoginError &&
                    <p className="error-text">{isLoginError}</p>}
                <div className="control-wrapper">
                    <div className="w-100">
                        <TextField error={isLoginError ? true : false} label="Email" name="email" required type="email" value={account.email} onChange={onInputChange} variant="outlined" />
                    </div>
                </div>
                {!isReset &&
                    <div className="control-wrapper">
                        <div className="w-100">
                            <TextField error={isLoginError ? true : false} label="Password" name="password" required type="password" 
                                value={account.password} onChange={onInputChange} variant="outlined" 
                                onKeyDown={(e) => {
                                    if(e.key === 'Enter' && !isReset){
                                        onLogin();
                                    }
                                    if(e.key === 'Enter' && isReset){
                                        onReset();
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
                {!isReset ?
                    <div className="d-flex justify-content-center pt-2">
                        <div className="p-2">
                            <Button disabled={isLoading === "loading"} onClick={onLogin} variant="contained" color="primary" >Login</Button>
                        </div>
                    </div> :
                    <div className="d-flex justify-content-center pt-2">
                        <div className="p-2">
                            <Button type="button" onClick={onReset} variant="contained" color="primary" >Reset Password</Button>
                        </div>
                    </div>
                }

                <div className="d-flex justify-content-center">
                    <div className="p-2">
                        <p className="text-link mb-0" onClick={() => setIsReset((prevState) => !prevState)}>
                            {isReset ? 'Remembered? Sign In' : 'Forgot Password? Reset here.'}
                        </p>
                    </div>
                </div>
            </form>
            </React.Fragment>
        </DocumentTitle>
    )
};

export default SignIn;