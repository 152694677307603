import * as React from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface IDropDown {
    arr: string[];
    labelKey?: string;
    setData?: any;
    isAutoWidth?: boolean;
}

const DropDown: React.FC<IDropDown> = ({ arr, labelKey, setData }) => {
    const [value, setValue] = React.useState<string>('');

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
        setData(event.target.value as string)
    };

    return (
        <Box sx={{ minWidth: 250, background: 'white', margin: '5px 0' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" size="small"> { labelKey ? labelKey : 'Filter' } </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value} size="small"
                    label={ labelKey ? labelKey : 'Filter' }
                    onChange={handleChange}
                    fullWidth
                >
                    {arr?.map((item, i) => (
                        <MenuItem key={i} value={item}>{ item }</MenuItem>
                    ))};
                </Select>
            </FormControl>
        </Box>
    );
};

export const AutoComplete: React.FC<IDropDown> = ({ arr, labelKey, setData, isAutoWidth }) => {
    const [value, setValue] = React.useState<string | null>();
    const [inputValue, setInputValue] = React.useState<string>('');
  
    return (
        <Box sx={{ minWidth: isAutoWidth ? "auto" : 250, maxWidth: isAutoWidth ? "auto" : 250, background: 'white', margin: '5px 10px 5px 0px' }}>
            <Autocomplete
                value={value} size="small"
                onChange={(event: any, newValue: string | null) => {
                    setValue(newValue);
                    setData(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={arr as any} fullWidth
                renderInput={(params) => <TextField {...params} label={labelKey} />}
            />
      </Box>
    );
}

export default DropDown;
