import moment from "moment";
import { IScreenFilter } from "./Interfaces";

class CommonMethods {
	
    public getToken = (): null | string => {
        return localStorage.getItem("accessToken");
    };

    public clearStorages = (): void => {
        localStorage.clear();
        sessionStorage.clear();
    };

	public getDateByFormat = (date: string, fr?: string): string => {
        return moment(date).format(fr);
    };

    public getEasternTimeZone = (date: string, fr?: string): string => {
        const format: string = fr ? fr : "MMMM Do YYYY, h:mm:ss a";
        return moment(date).utc().subtract(4, 'hours').format(format);
    };

	public subTractDays = (date: string, daySubtract: number): Date => {
		return moment(date).utc().subtract(daySubtract, 'd').toDate();
	};

    public humanize = (str: string) => {
        var i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }

    public getTimeRequested = (minus: number, startMidnight: boolean): number => {
		const now = new Date();

		if (minus === 0) now.setDate(now.getDate() - minus);
		else {
			now.setDate(now.getDate() - minus);
			now.setHours(23, 59, 59, 0);
		}

		if (startMidnight) return now.setHours(0, 0, 0, 0);
		else return now.getTime();
	};

	public isTodayDate = (date: number): boolean => {
		// date arg is timestamp
		let flag = false;

		const today = new Date();

		if (today.toDateString() === new Date(date).toDateString()) flag = true;

		return flag;
	};

	public isYesterdayDate = (date: number): boolean => {
		// date arg is timestamp
		let flag = false;

		const today = new Date();

		today.setDate(today.getDate() - 1); // 1 day previous from today

		const input = new Date(date);

		if (today.toDateString() === input.toDateString()) flag = true;

		return flag;
	};

    public dateToUnixStamp = (date: string): string | number => {
		return new Date(date).getTime();
	};

    public unixStampToDate = (unixStamp: number): string | number => {
		const date = new Date(unixStamp).toLocaleDateString('en-US').toString();

		const arrDate = date.split('/');

		const month = arrDate[0];

		const day = arrDate[1];

		const year = arrDate[2];

		return month + '-' + day + '-' + year;
	};

	public getScreenFilters = (included: number[]): IScreenFilter[] => {
		const filterArr: IScreenFilter[] = [
			{ id: 1, label: 'Created By', apiKey: 'created_by', value: "" },
			{ id: 2, label: 'Company', apiKey: 'company_name', value: "" },
			{ id: 3, label: 'Status', apiKey: 'status', value: "" },
			{ id: 4, label: 'Location Limit', apiKey: 'location_limit', value: "" },
			{ id: 5, label: 'Email', apiKey: 'user_email', value: "" },
			{ id: 6, label: 'TIV', apiKey: 'tiv', value: "" },
		];

		return filterArr.filter((f) => included.includes(f.id));
	};
}

const Methods = new CommonMethods();
export default Methods;
