import React from "react";
import ReactDOM from "react-dom/client";

import "./index.scss";
import 'antd/dist/antd.css';
import "antdstyle.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./Redux/Store/index";
import {Provider} from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { setAxiosInterceptors } from "Utilities/SetAuthToken";

setAxiosInterceptors();

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
