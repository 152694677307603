import ApiUrls from "Definations/ApiUrls";
import Methods from "Utilities/CommonMethods";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const adminLogin = async (data: any) => {
    return axios.post(`${BASE_URL}${ApiUrls.adminLogin}`, data, {
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export const adminLogout = async (data: any) => {
    const _token = Methods.getToken();
    return axios.post(`${BASE_URL}${ApiUrls.adminLogout}`, data, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status: number) => status >= 200 && status <= 404
    });
}