import axios from "axios";
import ApiUrls from "Definations/ApiUrls";
import Methods from "Utilities/CommonMethods";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUserList = async () => {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.getUsers}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}


export const getUserActivity = async (data: { page: number, user_email: string }) => {

    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.getUserActivity}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        params: {
            page: data.page,
            user_email: data.user_email
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export const getTemplateHistory = async (page: number) => {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.templateHistory}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        params: { page },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export const getReportedConstruction = async (page: number) => {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.allReportedCONSTRUCTION}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        params: { page },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export const deleteReportedConstruction = async (id: number | undefined) => {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.reportedConstructionDelete}${id}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export const getReportedOccupancy = async (page: number) => {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.allReportedOccupancy}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        params: { page },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}

export const deleteReportedOccupancy = async (id: number | undefined) => {
    const _token = Methods.getToken();
    return axios.get(`${BASE_URL}${ApiUrls.reportedOccupancyDelete}${id}`, {
        headers: {
            Authorization: `Token ${_token}`,
        },
        validateStatus: (status) => status >= 200 && status <= 404
    });
}


