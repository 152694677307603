import * as React from 'react';

import {Grid} from "@material-ui/core";
import AutoCompleteMultiple from 'Components/AutoComplete/AutoComplete';
import { TextField, ButtonGroup, Button } from "@mui/material";
import Dropdown from "Components/Dropdown/DropDown";
import { IScreenFilter } from 'Utilities/Interfaces';

interface ITriageFilter {
    getFilters: (url: string) => void;
    refreshListing: () => void;
}

const FILTERS_LIST = [
    { id: 1, name: "TIV", dropdown: false, textField: true, apiKey: 'tiv', greaterLessThen: true },
    { id: 2, name: "Construction", dropdown: false, textField: true, apiKey: 'construction', greaterLessThen: false },
    { id: 2.1, name: "Not Construction", dropdown: false, textField: true, apiKey: 'not_construction', greaterLessThen: false },
    { id: 3, name: "Occupancy", dropdown: false, textField: true, apiKey: 'occupancy', greaterLessThen: false },
    { id: 3.1, name: "Not Occupancy", dropdown: false, textField: true, apiKey: 'not_occupancy', greaterLessThen: false },
    { id: 4, name: "Postal Code", dropdown: false, textField: true, apiKey: 'postal', greaterLessThen: false },
    { id: 5, name: "Mapped County", dropdown: false, textField: true, apiKey: 'mapped_county', greaterLessThen: false },
    { id: 6, name: "County Tier", dropdown: false, textField: true, apiKey: 'county_tier', greaterLessThen: false },
    { id: 7, name: "State", dropdown: false, textField: true, apiKey: 'state', greaterLessThen: false },
    { id: 8, name: "Year Built", dropdown: false, textField: true, apiKey: 'year_built', greaterLessThen: true },
];

export const TriageFilters: React.FC<ITriageFilter> = (props) => {
    const { getFilters, refreshListing } = props;
    
    const [selectedFilters, setSelectedFilters] = React.useState<IScreenFilter[]>([]);
    const [operator, setOperator] = React.useState<string>('AND');

    const [values, setValues] = React.useState<{ [key: string]: any }>({});

    const defineFilterValues = React.useCallback(() => {
        const arr: string[] = [];
        for (const [key, value] of Object.entries(values) as [string, any])  {
            if ((value && typeof value === 'string') || (value || value === 0)) {
                arr.push(`${key}=${value}&`);
            }
        }
        const combine: string = arr.map(v => v).toString();
        const url: string = `query_type=${operator}&`+combine.slice(0, combine.length -1).split(',').join('');
        getFilters(url);
    }, [values, operator, getFilters]);

    React.useEffect(() => { 
        if (Object.keys(values).length > 0) {
            defineFilterValues();
        }
    }, [values, defineFilterValues]);

    const setValuesState = React.useCallback((key: string, val: any) => {
        if (!val && Object.keys(values).includes(key)) {
            delete values[key];
            setValues({ ...values });
        } else {
            setValues({ ...values, [key]: val });
        }
    }, [values]);

    const getCurrentValue = React.useCallback((key: string): string => {
        return values[key] ? values[key] : '';
    }, [values]);

    return React.useMemo(() => {
        return (
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap='wrap' spacing={0} style={{ marginBottom: 6, marginTop: 2 }}>
                <Grid item lg={3} xl={3} md={4} sm={4}>
                    <AutoCompleteMultiple mainLabel="Filters" minWidth={0} onDeleteChip={(str: IScreenFilter) => {
                        const k: string | undefined = FILTERS_LIST.find(f => f.name === str.label)?.apiKey;
                        
                        if (k && Object.keys(values).includes(k)){
                            delete values[k];
                            setValues({ ...values });

                            setTimeout(() => {
                                if (Object.keys(values).length === 0) {
                                    refreshListing();
                                }
                            }, 1000);
                        }
                    }} arr={FILTERS_LIST.map((c) => {
                        return { id: c.id, label: c.name, apiKey: c.apiKey, value: '' }
                    })} selected={selectedFilters} setSelected={setSelectedFilters} />
                </Grid>
                <Grid item style={{ margin: '0px 15px' }}>
                    <ButtonGroup size="medium" aria-label="small button group">
                        { ['AND', 'OR'].map((op) => (
                            <Button key={op} onClick={() => setOperator(op)} variant={operator === op ? 'contained' : 'outlined'}>{op}</Button>
                        )) }
                    </ButtonGroup>               
                </Grid>
                { FILTERS_LIST.filter(f => selectedFilters.map((f) => f.label).includes(f.name)).map((obj, ind: number) => {
                    let element = null;
                    if (obj.textField && !obj.greaterLessThen){
                        element = (
                            <TextField variant="outlined" size="small" value={getCurrentValue(obj.apiKey)} name={obj.name} 
                                onChange={(e) => setValuesState(obj.apiKey, e.target.value)}
                                label={obj.name} fullWidth key={ind}
                            />
                        );
                    }
    
                    if (obj.textField && obj.greaterLessThen){
                        element = (
                            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2} key={ind}>
                                <Grid item xs={6}>
                                    <TextField variant="outlined" size="small" value={getCurrentValue(`${obj.apiKey}_gte`)} name={`${obj.name}_gte`} 
                                        onChange={(e) => setValuesState(`${obj.apiKey}_gte`, e.target.value)}
                                        label={obj.name + ' Greater than'} fullWidth type="number"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField variant="outlined" size="small" value={getCurrentValue(`${obj.apiKey}_lte`)} name={`${obj.name}_lte`} 
                                        onChange={(e) => setValuesState(`${obj.apiKey}_lte`, e.target.value)}
                                        label={obj.name + ' Less than'} fullWidth type="number"
                                    />
                                </Grid>                            
                            </Grid>
                        );
                    }
    
                    if (obj.dropdown) {
                        element = (
                            <Dropdown labelKey={obj.name} arr={[]} setData={(item: string) => setValuesState(obj.apiKey, item)} key={ind} />
                        );
                    }
    
                    return <Grid key={ind} style={{ marginRight: 15 }} item lg={2} xl={2} md={3} sm={4}> {element} </Grid>;
                }) }
            </Grid>
        );
    }, [selectedFilters, values, operator, setValuesState, getCurrentValue, refreshListing]);    
}