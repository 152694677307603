import React, { useEffect } from "react";
import './Analytics.scss';

import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import { AppDispatch } from "Redux/Store/index";
import MapIcon from '@mui/icons-material/Map';
import { analyticsSelector, getDashboardAnalytics } from "Redux/Slices/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
  

export const AnalyticCard: React.FC<{count:number, title: string, icon: any, loading?: boolean}> = ({count, title, icon, loading}) => {
    return (
        <div className="analytic bg-white shadow d-flex align-items-center" style={{ marginBottom: 5 }}>
            <div className="analytic-content flex-grow-1">
                <h4 className="count">{ loading ? <CircularProgress size={14} color="primary" /> : count}</h4>
                <p className="title">{title}</p>
            </div>
            <div className="analytic-icon">
                <div className="d-flex justify-content-center align-items-center">{icon}</div>
            </div>
        </div>
    )
}

const Analytics: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const analytics = useSelector(analyticsSelector);
    useEffect(()=> {
        dispatch(getDashboardAnalytics(undefined));
    }, []);

    return (
        <div className="analytics-container d-flex flex-wrap">
            <AnalyticCard count={analytics?.user_count ?? 0} title={'Number of Users'} icon={<GroupIcon />} />
            <AnalyticCard count={analytics?.processed_sov_count ?? 0} title={'Processed SOVs'} icon={<ArticleIcon />} />
            <AnalyticCard count={analytics?.average_location_count ?? 0} title={'Average Locations'} icon={<MapIcon />} />
            <AnalyticCard count={analytics?.unique_sov_count ?? 0} title={'Unique SOVs'} icon={<ArticleIcon />} />
        </div>
    )
};

export default Analytics;