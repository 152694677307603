import React, { useState, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Input, Button, message } from 'antd';
import Chip from '@mui/material/Chip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ApiUrls from "Definations/ApiUrls";
import axios from 'axios';
import Methods from "Utilities/CommonMethods";

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
    },
}));

interface IConfiguration {
    open: boolean;
    setOpen: (f: boolean) => void;
}

export const Configuration:React.FC<IConfiguration> = ({ open, setOpen }) => {

    const classes = useStyles();
    const [APis, setAPis] = useState<string>('');
    const [ApiList, setApiList] = useState<any[]>([]);
    const [savedApi, setSavedApi] = React.useState([]);
    const theme = useTheme();

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e: any) => {
        setAPis(e.target.value);
    }

    const Save = () => {
        const data = axios.put(`${process.env.REACT_APP_BASE_URL}${ApiUrls.hazardHub}`, { "hazard_hub_keys": ApiList }, {
            headers: {
                Authorization: `Token ${Methods.getToken()}`
            }

        }).then(res => {
            message.success('Successfully Saved');
            setOpen(false);
            FetchAll();
            setApiList([])

        }).catch(err => {
            message.error(err.message);
        })

    }

    const handleAdd = useCallback((e: any) => {
        if (APis.length > 0) {
            setApiList([...ApiList, APis]);
            setAPis('');

        } else {
            message.error('Please enter API');
        }
    }, [APis, ApiList]);

    const FetchAll = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}${ApiUrls.hazardHub}`, {
            headers: {
                Authorization: `Token ${Methods.getToken()}`
            }

        }).then(res => {
            setSavedApi(res.data.hazard_hub_key?res.data.hazard_hub_key:[]);
        }).catch(err => {
            message.error(err.message);
        });
    }

    React.useEffect(() => {
        FetchAll()
    }, []);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            aria-labelledby="responsive-dialog-title"
            aria-describedby="responsive-dialog-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            fullScreen={fullScreen}
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">Enter Hazard Hub API Key</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Input.Group compact>
                        <Input
                            style={{
                                width: 'calc(100%-70px)'
                            }}
                            defaultValue="iuyrw8957wue29"
                            onChange={handleChange}
                            value={APis}
                            placeholder="Enter API Key"
                        />
                        <Button type="primary" onClick={handleAdd}>Add</Button>
                    </Input.Group>
                </DialogContentText>

                {savedApi.map((item: any, index: number) => <Chip key={index} label={item} sx={{ marginRight: 2 }} />)}

                <br />
                {ApiList.map((item: any, index: number) => <Chip key={index} label={item} sx={{ marginRight: 2 }} />)}

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        close
                    </Button>
                    <Button onClick={Save} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};