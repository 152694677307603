import * as React from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import {useDispatch, useSelector} from "react-redux";
import {currentUserSelector, onAdminLogout} from "Redux/Slices/Auth";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {AppDispatch} from "Redux/Store";
import { Configuration } from "Pages/Configuration/Configuration";
import {Grid} from "@material-ui/core";
import Badge from '@mui/material/Badge';
import Chip from "@mui/material/Chip";

export default function UserMenu() {

    const currentUser: any = useSelector(currentUserSelector);
    const dispatch: AppDispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [hazardHubModal, setHazardHubModal] = React.useState<boolean>(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleLogout = (): void => {
        dispatch(onAdminLogout(undefined));
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 40, height: 40 }}>
                            <Badge color="success" variant="dot">
                                { currentUser?.email?.split('@')[0]?.slice(0, 1)?.toUpperCase() }
                            </Badge>
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 300,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    { [
                        { title: 'Email: ', value: currentUser?.email },
                        { title: 'Company Name: ', value: currentUser?.company_id?.company_name },
                        { title: 'Company Code: ', value: currentUser?.company_id?.company_code }
                    ].map((item, index) => (
                        <Grid item xs key={index}>
                            <strong> { item.title } </strong>
                            <span> {item.value} </span>
                        </Grid>
                    )) }
                    <br />
                    <Grid item xs>
                        { [
                            { condition: currentUser?.is_admin, value: 'Admin' },
                            { condition: currentUser?.is_staff, value: 'Staff' }
                        ].map((item, index) => (
                            item.condition && <Chip key={index} label={item.value} sx={{ marginRight: 1 }} />
                        )) }
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <MenuItem disabled={true}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Add another account
                </MenuItem>
                <MenuItem disabled={true}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={() => setHazardHubModal(true)}>
                    <ListItemIcon>
                        <VpnKeyIcon fontSize="small" />
                    </ListItemIcon>
                    HazardHub Key
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>

            <Configuration open={hazardHubModal} setOpen={setHazardHubModal} />

        </React.Fragment>
    );
}
