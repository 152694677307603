import React, { useEffect, useState } from 'react';

import ApiUrls from "Definations/ApiUrls";
import AlertDialogSlide from 'Shared/SharedModal'
import { DeleteOutline } from '@mui/icons-material';
import { Tooltip, Table } from 'antd';
import { handleUpdatedFilter, SortingData} from 'Utilities/Pagination';
import Methods from "Utilities/CommonMethods";
import {DocumentTitle} from "Components/DocumentTitle/DocumentTitle";
import { useFetchDeleteReportedOccupancy } from "Hooks/UseFetchSOV";
import Pagination from "@mui/material/Pagination";
import {AlertPopup} from "Components/Alert/Alert";
import ScreenFilter from 'Components/ScreenFilter/ScreenFilter';
import { TextField } from '@mui/material';
import { useDebounce } from 'Hooks/UseDebounce';
import { IScreenFilter } from 'Utilities/Interfaces';

const mockData = [];
for (let i = 0; i < 20; i++) {
    mockData.push({
        key: i.toString(),
        title: `content${i + 1}`,
        description: `description of content${i + 1}`,
    });
}

const ReportedOccupancy = (props: any) => {

    const [page, setPage] = React.useState<number>(1);
    const [selectedFilters, setSelectedFilters] = React.useState<IScreenFilter[]>([]);
    const [search, setSearch] = useState<string>('');
    const [id, SetId] = useState();
    const [updatedoccupancy, setUpdatedOccupancy] = React.useState<any>([]);
    const [modal, setModal] = useState(false);
    const [totalCount, setUpdateCount] = React.useState<number>(10);
    const [isError, setIsError] = React.useState<{ data: any, flag: boolean }>({ data: '', flag: false });
    const [sort, setSort] = React.useState<any>();

    const { triggered } = useFetchDeleteReportedOccupancy(id);    
    const debouncedValue: any = useDebounce(selectedFilters.length > 0 ? selectedFilters[0].value : '', 1000);

    useEffect(() => {
        if (debouncedValue) {
            handleFilters(debouncedValue);
        } else {
            handleChange(1);   
        }
    }, [debouncedValue]);

    const actionDelete = (): void => {
        triggered();

        setTimeout(() => {
            handleChange(page);
            setModal(false);
        }, 500);
    }

    const columns = [
        {
            title: 'Company',
            dataIndex: 'company_name',
        },
        {
            title: 'Occupancy',
            dataIndex: 'occupancy',
        },
        {
            title: 'Occupancy Name',
            dataIndex: "occupancy_code_id",
            render: (p: any, record: any) => <p>{record?.occupancy_code_id?.occupancy}</p>
        },
        {
            title: 'Occupancy Type',
            dataIndex: "occupancy_code_id",
            render: (p: any, record: any) => <p>{record?.occupancy_code_id?.code_type}</p>
        },
        {
            title: 'Occupancy Code',
            dataIndex: "occupancy_code_id",
            render: (p: any, record: any) => <p>{record?.occupancy_code_id?.code}</p>
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            sorter: true,
            render: (p: any, record: any) => <p>{ Methods.getEasternTimeZone(p)}</p>
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            sorter: true,
            render: (p: any, record: any) => <p>{p}</p>
        },
        {
            title: 'Mapping Count',
            dataIndex: 'count',
            render: (p: any, record: any) => <p>{p}</p>
        },
        {
            title: 'Match Percentage',
            dataIndex: 'percentage',
            render: (p: any, record: any) => <p>{`${p}%`}</p>
        },
        {
            title: 'Action',
            render: (p: any, record: any) => (

                <div className="dropdown dropdown-action text-end">
                    <Tooltip placement="right" color="red" title="Delete">
                        <a onClick={() => deleterow(record?.id)}><DeleteOutline /></a>
                    </Tooltip>
                </div>
            ),
        }
    ];

    const deleterow = (id: any): void => {
        setModal(true);
        SetId(id);
    }

    const handleTableChange = (newPagination: any, filters: any, sorter: any): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        const obj = {page, filter, filterValue};

        setSort(sorter);
        SortingData(sorter, ApiUrls.allReportedOccupancy, setUpdatedOccupancy, obj, (err: any) => (setIsError(err)) );
    };

    const handleFilters = React.useCallback((value: string): void => {
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';
        setPage(1);

        if(filter){
            handleUpdatedFilter(value, setUpdatedOccupancy, ApiUrls.allReportedOccupancy, page, value ? filter : '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort, undefined );
        }
    }, [page, selectedFilters, sort]);

    const handleChange = React.useCallback((pg: number): void => {
        const filterValue = selectedFilters.length > 0 ? selectedFilters[0].value : '';
        const filter = selectedFilters.length > 0 ? selectedFilters[0].apiKey : '';

        handleUpdatedFilter(filterValue, setUpdatedOccupancy, ApiUrls.allReportedOccupancy, pg, filterValue ? filter : '', true, (flag: number)=>(setUpdateCount(flag)), (err: any) => (setIsError(err)), sort );
    }, [selectedFilters, sort]);

    return (
        <DocumentTitle title='Reported Occupancy' condition={true}>
            <div className="page-wrapper">
                
                {modal && <AlertDialogSlide data="Are you sure you want to delete" title="Confirm Delete" setmodal={(value: boolean) => setModal(value)} actionDelete={actionDelete}></AlertDialogSlide>}
                
                <ScreenFilter 
                    dropdownArray={Methods.getScreenFilters([1, 2])} setSelectedFilters={setSelectedFilters}
                    selectedFilters={selectedFilters} onSearch={(txt) => handleFilters(txt)} totalRows={totalCount}
                >
                    <TextField 
                        variant="outlined" size="small" value={search} name="occupancy" 
                        onChange={(e) => setSearch(e.target.value)} label="Search" sx={{ width: 250 }}                        
                    />
                </ScreenFilter>                    
                
                {/* Page Content */}
                <div className="table-responsive shadow bg-white p-2 mt-2">
                    <Table className="table-striped"
                        pagination={false}
                        columns={columns}
                        dataSource={updatedoccupancy}
                        rowKey={record => record.id}
                        onChange={handleTableChange}
                    />
                </div>

                <Pagination count={Math.ceil(totalCount/10)} page={page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                    setPage(value);
                    handleChange(value);
                }} style={{ marginTop: 10 }} />

                {isError?.flag && <AlertPopup open={isError?.flag} setClose={(flg: boolean)=>setIsError({...isError, flag: flg}) } />}
            </div>
        </DocumentTitle>
    );
}
export default ReportedOccupancy

